<template>
    <div class="innerContent">
        <div style="height: calc(100vh - 66px); overflow-y: scroll;" class="pa-2">
              <v-card class="infoCards" flat>
                <TopBanner :shipment="shipment"/>
              </v-card>
                <v-row no-gutters class="pt-2" style="min-height: calc(100% - 64px)">
                    <v-col cols="12" sm="12" md="6" lg="3" id="GeneralInfo" style="display: flex; flex-direction: column;">
                      <v-card class="infoCards mb-2" flat id="Statuses">
                        <v-subheader style="height: 30px;" class="px-2">
                              <v-icon small class="mr-2">verified</v-icon>Statuses
                          </v-subheader> 
                          <v-divider></v-divider>
                          <v-row no-gutters>
                            <v-col cols="12" sm="12" md="5" lg="5" class="py-2">
                              <v-list dense style="background: transparent !important;" class="pa-0">
                                <v-list-item class="mt-0 pt-0 mb-2">
                                      <v-list-item-content class="pl-2">
                                          <v-list-item-title>
                                              <v-chip small :color="getStatusColor(order.orderStatus, 'orderStatus')" class="px-2">
                                                  {{ order.orderStatus }}
                                              </v-chip>
                                          </v-list-item-title>
                                          <v-list-item-subtitle>
                                              Order Status
                                          </v-list-item-subtitle>
                                      </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item class="mt-0 pt-0">
                                      <v-list-item-content class="pl-2">
                                          <v-list-item-title>
                                            <v-chip small :color="getStatusColor(order.state, 'state')" class="px-2">
                                              {{  order.state }}
                                            </v-chip>
                                          </v-list-item-title>
                                          <v-list-item-subtitle>
                                              Forwarder Status
                                          </v-list-item-subtitle>
                                      </v-list-item-content>
                                  </v-list-item>
                              </v-list>
                            </v-col>
                            <v-col cols="12" sm="12" md="7" lg="7" class="d-flex pt-1" style="height: 100%;">
                              <div class="d-flex" style="flex-direction: column;">
                                  <span style="opacity: 0.8;">Containers</span>
                                <v-divider class="mb-1"></v-divider>
                                <span style="font-size: 0.625rem; opacity: 0.7; display: flex; align-items: center;" class="mb-1">
                                  <v-icon class="mr-1" small color="success">check</v-icon>
                                    <b class="mr-1">{{totalPackedContainers}}</b> Completed
                                </span>
                                <span style="font-size: 0.625rem; opacity: 0.7; display: flex; align-items: center;" class="mb-1">
                                  <v-icon class="mr-1" small color="orange">update</v-icon>
                                    <b class="mr-1">{{totalInProgressContainers}}</b> In Progress
                                </span>
                                <span style="font-size: 0.625rem; opacity: 0.7; display: flex; align-items: center;" class="mb-1">
                                  <v-icon class="mr-1" small color="grey">check_box_outline_blank</v-icon>
                                    <b class="mr-1">{{totalEmptyContainers}}</b> Empty
                                </span>
                                <span style="font-size: 0.625rem; opacity: 0.7; display: flex; align-items: center;" v-if="totalProblematicContainers > 0">
                                  <v-icon class="mr-1" small color="danger">error_outline</v-icon>
                                  <b class="mr-1">{{totalProblematicContainers}}</b> Unassigned
                                </span>
                              </div>
                              <v-spacer></v-spacer>
                              <div class="d-flex align-center">
                                <v-progress-circular class="mr-2" :size="80" :width="12" :value="averageProgress"
                                :color=" averageProgress == 0 ? 'grey' : averageProgress < 20 ? 'red' : averageProgress < 50 ? 'orange' : averageProgress < 100 ? 'blue' : 'success' ">
                                  {{ averageProgress + '%' }}
                                </v-progress-circular>
                              </div>
                            </v-col>
                            </v-row>
                      </v-card>
                      <v-card class="infoCards mb-2" flat id="References">
                          <v-subheader style="height: 30px;" class="px-2">
                              <v-icon small class="mr-2">tag</v-icon>References
                          </v-subheader>
                          <v-divider></v-divider>
                          <v-row no-gutters>
                            <v-col cols="12" sm="6" md="6" lg="6">
                              <v-list dense style="background: transparent !important;" class="pa-0">
                              <v-list-item class="mb-1" :disabled="order.bookingRequestStatus=='ACCEPTED' && order.bookingHandlerId !=null" @click="editReference('carrierReferenceNumber','Carrier Reference')">
                                  <v-list-item-content class="pl-2">
                                      <v-list-item-title>
                                          {{ order.carrierReferenceNumber ?? "-" }}
                                      </v-list-item-title>
                                      <v-list-item-subtitle>
                                        Carrier Reference No
                                      </v-list-item-subtitle>
                                  </v-list-item-content>
                              </v-list-item>
                              <v-list-item class="mt-0 pt-0" @click="editReference('shipperReferenceNo','Shipper Reference')">
                                  <v-list-item-content class="pl-2">
                                      <v-list-item-title>
                                          {{ order.shipperReferenceNo ?? "-" }}
                                      </v-list-item-title>
                                      <v-list-item-subtitle>
                                      Shipper Ref No
                                      </v-list-item-subtitle>
                                  </v-list-item-content>
                              </v-list-item>
                            </v-list>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" lg="6">
                              <v-list dense style="background: transparent !important;" class="pa-0">
                              <v-list-item class="mt-0 pt-0 mb-1" @click="editReference('consigneeReferenceNo','Consignee Reference')">
                                    <v-list-item-content class="pl-2">
                                        <v-list-item-title>
                                            {{ order.consigneeReferenceNo ?? "-" }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            Consignee Ref No
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                            </v-col>
                          </v-row>
                      </v-card>
                      <v-card class="infoCards pb-2 mb-2" flat id="DealTerms" >
                        <v-subheader style="height: 30px;" class="px-2">
                              <v-icon small class="mr-2">handshake</v-icon>Deal Terms
                          </v-subheader>
                          <v-divider></v-divider>
                          <div class="d-flex">
                            <v-menu :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on }">
                                    <v-list-item v-on="on">
                                        <v-list-item-action>
                                            <v-icon small color="grey"> tag </v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title v-if="orderFinancialSetting.incoTerm">
                                                {{ orderFinancialSetting.incoTerm }}
                                            </v-list-item-title>
                                            <v-list-item-title v-else> - </v-list-item-title>
                                            <v-list-item-subtitle> INCO Term </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                                <v-list dense>
                                    <v-list-item v-for="incoterm in incoTerms" :key="incoterm.id" @click="orderFinancialSetting.incoTerm = incoterm.abbreviation; saveOrderFinancialSetting();">
                                        <v-list-item-title>{{incoterm.abbreviation}}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                            <v-list-item @click="currencyModal = true"> 
                                <v-list-item-action>
                                    <v-icon small color="grey">currency_exchange</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-if="orderFinancialSetting.baseCurrency">
                                        {{ orderFinancialSetting.baseCurrency }}
                                        <span class="ml-2">{{ getSymbol(orderFinancialSetting.baseCurrency) }}</span>
                                    </v-list-item-title>
                                    <v-list-item-title v-else>-</v-list-item-title>
                                    <v-list-item-subtitle>Base Currency</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                          </div>
                          <v-menu :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
                              <template v-slot:activator="{ on }">
                                  <v-list-item v-on="on">
                                      <v-list-item-action>
                                          <v-icon small color="grey"> trending_up </v-icon>
                                      </v-list-item-action>
                                      <v-list-item-content>
                                          <v-list-item-title v-if="orderFinancialSetting.type == 'FIXED'">
                                              Fixed Pricing
                                          </v-list-item-title>
                                          <v-list-item-title v-else-if="orderFinancialSetting.type == 'MGP'">
                                              Consignment: Minimum Guaranteed Price
                                          </v-list-item-title>
                                          <v-list-item-title v-else-if="orderFinancialSetting.type == 'FULL_CONSIGNMENT'">
                                            Full Consignment
                                          </v-list-item-title>
                                          <v-list-item-subtitle>Price Terms</v-list-item-subtitle>
                                      </v-list-item-content>
                                      <v-list-item-action>
                                          <v-icon small>expand_more</v-icon>
                                      </v-list-item-action>
                                  </v-list-item>
                              </template>
                              <v-list dense>
                                  <v-list-item @click="orderFinancialSetting.type = 'FIXED'; saveOrderFinancialSetting();">
                                      <v-list-item-title>Fixed Pricing</v-list-item-title>
                                  </v-list-item>
                                  <v-list-item @click="orderFinancialSetting.type = 'MGP'; saveOrderFinancialSetting();">
                                      <v-list-item-title>Consignment: Minimum Guaranteed Price</v-list-item-title>
                                  </v-list-item>
                                  <v-list-item @click="orderFinancialSetting.type = 'FULL_CONSIGNMENT';saveOrderFinancialSetting();">
                                      <v-list-item-title>Full Consignment</v-list-item-title>
                                  </v-list-item>
                              </v-list>
                          </v-menu>
                      </v-card>
                      <v-card class="infoCards" flat style="height: 100%;" id="Parties">
                        <v-subheader style="height: 30px;" class="px-2">
                            <v-icon small class="mr-2">groups</v-icon>Parties
                        </v-subheader>
                        <v-divider></v-divider>
                        <v-list dense subheader style="background: transparent !important;">
                            <v-list-item v-if="order.orderCustomer">
                                <v-list-item-avatar>
                                  <v-avatar size="24" :color="order.orderCustomer.logo ? 'white' : 'secondary'">
                                    <v-img v-if="order.orderCustomer.logo" :src="order.orderCustomer.logo" contain />
                                    <h2 v-else>
                                      {{ order.orderCustomer.name.charAt(0) }}
                                    </h2>
                                  </v-avatar>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ order.orderCustomer.name }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        Selected Customer
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action style="width: 60px !important; flex-direction: row; justify-content: flex-end !important; align-items: center;" class="d-flex">
                                    <v-btn plain x-small @click="customerSearchModal = true" icon><v-icon small>edit</v-icon></v-btn>
                                    <v-btn plain x-small @click="removeCustomer()" color="red" icon><v-icon small>delete</v-icon></v-btn>
                                </v-list-item-action>
                            </v-list-item>
                            <v-list-item v-else @click="customerSearchModal = true">
                                <v-list-item-action>
                                    <v-icon small color="primary">add_circle_outline</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <span style="font-size: 16px">Add Customer</span>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item :disabled="!order.orderCustomer" :key="profileKey">
                                <v-list-item-action>
                                  <v-icon small color="secondary">handshake</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title v-if="order.consigneeProfile">
                                    {{ order.consigneeProfile?.systemReference ?? '-' }}
                                  </v-list-item-title>
                                  <v-list-item-title v-else> - </v-list-item-title>
                                  <v-list-item-subtitle>
                                    Shipment Profile
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action style="width: 60px !important; flex-direction: row; justify-content: flex-end !important; align-items: center;" class="d-flex" >
                                    <v-btn v-if="order.consigneeProfile" :disabled="!order.orderCustomer" plain x-small @click="shipmentProfileDialog = true" icon ><v-icon small>edit</v-icon></v-btn>
                                    <v-btn v-if="order.consigneeProfile" :disabled="!order.orderCustomer" plain x-small @click="removeProfile()" color="red" icon><v-icon small>delete</v-icon></v-btn>
                                    <v-btn icon color="primary" v-if="!order.consigneeProfile" :disabled="!order.orderCustomer" @click="shipmentProfileDialog = true">
                                        <v-icon small>add_circle_outline</v-icon>
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                            <v-list-item v-if="order.consigneeProfile" @click="shipmentProfileConfig = true">
                                <v-list-item-action>
                                    <v-icon small v-if="order.dealTerm" color="secondary">settings</v-icon>
                                    <v-icon small v-else color="primary">add_circle_outline</v-icon>
                                </v-list-item-action>
                                <v-list-item-content v-if="!order.dealTerm">
                                    <span style="font-size: 16px">Select Profile Inco Term</span>
                                </v-list-item-content>
                                <v-list-item-content v-else>
                                <v-list-item-title>
                                  {{ order.dealTerm.incoTerm }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  Profile Inco Term
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="incoTermModal = true" v-if="!order.consigneeProfile">
                                <v-list-item-action>
                                    <v-icon small :color="!order.incoTerm ? 'secondary' : 'grey'">swap_horiz</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-if="order.incoTerm">
                                    {{ order.incoTerm }}
                                    </v-list-item-title>
                                    <v-list-item-title v-else>-</v-list-item-title>
                                    <v-list-item-subtitle> Inco Term </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item disabled @click="(partyModal = true), (partyType = 'Shipper')">
                                <v-list-item-action>
                                    <v-icon small class="mt-0 pt-0" color="grey">directions_boat_filled</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-if="order.shipper">
                                        {{ order.shipper.name }}
                                    </v-list-item-title>
                                    <v-list-item-title v-else> - </v-list-item-title>
                                    <v-list-item-subtitle v-if="order.onBehalfShipper">
                                        On behalf of
                                        {{ order.onBehalfShipper.name }}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        <v-icon x-small color="grey" class="mr-1">label</v-icon>Shipper
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item disabled @click="(partyModal = true), (partyType = 'Forwarder')">
                                <v-list-item-action>
                                    <v-icon small class="mt-0 pt-0" color="grey">fast_forward</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-if="order.forwarder">
                                        {{ order.forwarder.name }}
                                    </v-list-item-title>
                                    <v-list-item-title v-else> - </v-list-item-title>
                                    <v-list-item-subtitle v-if="order.onBehalfForwarder">
                                        On behalf of
                                        {{ order.onBehalfForwarder.name }}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        <v-icon x-small color="grey" class="mr-1">label</v-icon>Forwarder
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item disabled  @click="(partyModal = true), (partyType = 'Consignee')" >
                                <v-list-item-action>
                                  <v-icon small class="mt-0 pt-0" color="grey">call_received</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-if="order.consignee">
                                        {{ order.consignee.name }}
                                    </v-list-item-title>
                                    <v-list-item-title v-else> - </v-list-item-title>
                                    <v-list-item-subtitle v-if="order.onBehalfConsignee">
                                        On behalf of
                                        {{ order.onBehalfConsignee.name }}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        <v-icon x-small color="grey" class="mr-1">label</v-icon>Consignee
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item disabled  @click="(partyModal = true), (partyType = 'Buyer')" >
                                <v-list-item-action>
                                    <v-icon small class="mt-0 pt-0" color="grey">shopping_cart</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-if="order.buyer">
                                        {{ order.buyer.name }}
                                    </v-list-item-title>
                                    <v-list-item-title v-else> - </v-list-item-title>
                                    <v-list-item-subtitle v-if="order.onBehalfBuyer">
                                        On behalf of {{ order.onBehalfBuyer.name }}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        <v-icon x-small color="grey" class="mr-1">label</v-icon>Buyer
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item disabled  @click="(partyModal = true), (partyType = 'First Notify')">
                                <v-list-item-action>
                                    <v-icon small class="mt-0 pt-0" color="grey">notifications</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-if="order.firstNotify">
                                        {{ order.firstNotify.name }}
                                    </v-list-item-title>
                                    <v-list-item-title v-else> - </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <v-icon x-small color="grey" class="mr-1">label</v-icon>Notify Party
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item  disabled  @click="(partyModal = true), (partyType = 'Second Notify')">
                                <v-list-item-action>
                                    <v-icon small class="mt-0 pt-0" color="grey">notifications</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-if="order.secondNotify"> 
                                        {{ order.secondNotify.name }}
                                    </v-list-item-title>
                                    <v-list-item-title v-else> - </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <v-icon x-small color="grey" class="mr-1">label</v-icon>2nd Notify Party
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item  disabled @click="(partyModal = true), (partyType = 'Courier Party')">
                                <v-list-item-action>
                                    <v-icon small class="mt-0 pt-0" color="grey">local_post_office</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-if="order.courierParty">
                                        {{ order.courierParty.name }}
                                    </v-list-item-title>
                                    <v-list-item-title v-else> - </v-list-item-title>
                                    <v-list-item-subtitle>
                                    <v-icon x-small color="grey" class="mr-1">label</v-icon>Courier Party
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                      </v-card>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="9" class="pl-2" id="Map" style="display: flex; flex-direction: column;">
                      <div class="mb-2">
                        <ContainerTracking :hideLegs="true" :height="'50vh'" :container="selectedContainer" v-if="!loadingContainers"/>
                      </div>
                        <v-card class="infoCards" style="height: 100%; contain: content;" flat v-if="loadingContainers">
                          <div class="overlayMap" v-if="loadingMap || noTrackingData">
                            <v-progress-circular indeterminate v-if="loadingMap"></v-progress-circular>
                            <span>{{ loadingMapMessage }}</span>
                          </div>
                          </v-card>
                          <v-card class="infoCards" flat style="height: 100%">
                            <v-subheader style="height: 30px;" class="px-2">
                              <v-icon small class="mr-2">view_column</v-icon>Container Summary
                              <v-spacer></v-spacer>
                              <v-btn x-small color="blue" rounded outlined @click="assignStock()" :disabled="$store.state.currentOrg.id == 2507">
                                <v-icon left small>assignment_turned_in</v-icon>
                                Assign Stock
                              </v-btn>
                              <v-btn icon color="primary" @click="addOrderItem()" :disabled="$store.state.currentOrg.id == 2507">
                                <v-icon>add_circle_outline</v-icon>
                              </v-btn>
                            </v-subheader>
                            <v-divider></v-divider>
                            <div v-for="(container, index) in containers" :key="index">
                              <v-row no-gutters class="pb-2 px-2 pt-0" >
                                <v-col cols="12" sm="2" md="2" lg="2" class="d-flex align-center pt-5">
                                    <div>
                                      <v-icon v-if="container.allocatedPallets && !container.totalPallets" color="danger">error_outline</v-icon>
                                      <v-progress-circular v-else :value="container.allocatedProgress" size="24" :color=" container.totalPallets == 0 ? 'grey' : container.allocatedProgress < 20 ? 'red' : container.allocatedProgress < 50 ? 'orange' : container.allocatedProgress < 100 ? 'blue' : 'success' " />
                                    </div>
                                    <div style="display: flex; flex-direction: column;" class="pl-2">
                                      <span>{{ container.ctoNo }}</span>
                                      <span style="font-size: 10px; opacity: 0.7;">{{ container.containerNo }} ({{ container.containerType }})</span>
                                      <span style="font-size: 10px; opacity: 0.7;">{{ container.allocatedPallets }} Allocated Pallets</span>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="10" md="10" lg="10">
                                  <ProductBreakdownTable :noHeader="true" :dense="true" :transparent="true" :headers="productSummaryHeaders" :items="container.productSummary" :tableKey="productKey" :loading="loadingContainerContents.includes(container.id)" :height="'100%'"/>
                                </v-col>
                              </v-row>
                            </div>
                          </v-card>
                    </v-col>
                  </v-row>
              </div>
        <v-dialog v-model="currencyModal" width="400px">
          <v-card>
            <v-toolbar flat>
              <v-toolbar-title>Select Currency</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn @click="currencyModal = false" text>X</v-btn>
            </v-toolbar>
            <v-card-text>
              <v-text-field
                v-model="searchCurrency"
                prepend-inner-icon="search"
                placeholder="Search Currency"
                outlined
                dense
              >
              </v-text-field>
              <v-list dense style="max-height: 60dvh; overflow-y: auto">
                <v-list-item v-if="filterCurrencies.length == 0">
                  <v-list-item-content class="text-center">
                    <span style="color: grey; font-size: 12px"
                      >No available currencies</span
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-for="(currency, index) in filterCurrencies"
                  :key="index"
                  @click="setBaseCurrency(currency)"
                >
                  <v-list-item-action style="width: 100px">
                    <v-chip small color="primary">
                      <h4 class="primaryText--text">{{ getSymbol(currency) }}</h4>
                    </v-chip>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ currency }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="shipmentProfileDialog" width="90vw" :fullscreen="$vuetify.breakpoint.mobile" persistent >
          <v-card v-if="shipmentProfileDialog">
            <v-card-title>
              Search Shipment Profiles
              <v-spacer></v-spacer>
              <v-btn text @click="shipmentProfileDialog = false">X</v-btn>
            </v-card-title>
            <v-card-text>
              <v-row justify="end">
                <v-col cols="12" sm="4">
                  <v-text-field
                    placeholder="Search"
                    autofocus
                    prepend-inner-icon="search"
                    v-model="searchProfiles"
                    outlined
                    dense
                    clearable
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-data-table
                id="profile-info"
                style="cursor: pointer; font-size: 12px"
                :items="filteredProfiles"
                dense
                :loading="loadingShipmentProfiles"
                :headers="shipmentProfileHeaders"
                fixed-header
                :search="searchProfiles"
                height="70vh"
                @click:row="setShipmentProfile"
              >
                <template v-slot:[`item.systemReference`]="{ item }">
                  <b>{{ item.systemReference }}</b>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <v-chip
                    v-if="item.status == 'Draft'"
                    color="blue"
                    class="white--text"
                    small
                    >Draft</v-chip
                  >
                  <v-chip
                    v-else-if="item.status == 'Approved'"
                    color="success"
                    class="white--text"
                    small
                    >Approved</v-chip
                  >
                </template>
                <template v-slot:[`item.shipper.name`]="{ item }">
                  <span v-if="item.shipper">{{ item.shipper.name }}</span>
                  <span>
                    <span v-if="item.onBehalfShipper">
                      <br />
                      On behalf of {{ item.onBehalfShipper.name }}</span
                    >
                  </span>
                </template>
                <template v-slot:[`item.forwarder.name`]="{ item }">
                  <span v-if="item.forwarder">{{ item.forwarder.name }}</span>
                  <span>
                    <span v-if="item.onBehalfForwarder">
                      <br />
                      On behalf of {{ item.onBehalfForwarder.name }}</span
                    >
                  </span>
                </template>
                <template v-slot:[`item.consignee.name`]="{ item }">
                  <span v-if="item.consignee">{{ item.consignee.name }}</span>
                  <span>
                    <span v-if="item.onBehalfConsignee">
                      <br />
                      On behalf of {{ item.onBehalfConsignee.name }}</span
                    >
                  </span>
                </template>
                <template v-slot:[`item.buyer.name`]="{ item }">
                  <span v-if="item.buyer">{{ item.buyer.name }}</span>
                  <span>
                    <span v-if="item.onBehalfBuyer">
                      <br />
                      On behalf of {{ item.onBehalfBuyer.name }}</span
                    >
                  </span>
                </template>
                <template v-slot:[`item.loadingPorts`]="{ item }">
                  <v-chip
                    color="success"
                    pill
                    outlined
                    small
                    v-if="item.allOriginPorts"
                  >
                    <v-icon small left>check</v-icon> All Ports</v-chip
                  >
                  <div v-else>
                    <span
                      v-for="(port, i) in item.consigneeProfilePorts.filter(
                        (x) => x.type == 'loading'
                      )"
                      :key="port.id"
                    >
                      <span v-if="i > 0">, </span>{{ port.name }}</span
                    >
                  </div>
                </template>
                <template v-slot:[`item.dischargePorts`]="{ item }">
                  <v-chip
                    color="success"
                    pill
                    outlined
                    small
                    v-if="item.allDestinationPorts"
                  >
                    <v-icon small left>check</v-icon> All Ports</v-chip
                  >
                  <div v-else>
                    <span
                      v-for="(port, i) in item.consigneeProfilePorts.filter(
                        (x) => x.type == 'discharge'
                      )"
                      :key="port.id"
                    >
                      <span v-if="i > 0">, </span>{{ port.name }}</span
                    >
                  </div>
                </template>
                <template v-slot:[`item.originCountry.name`]="{ item }">
                  <v-tooltip top v-if="item.originCountry">
                    <template v-slot:activator="{ on }">
                      <v-avatar size="32" v-on="on">
                        <v-img
                          contain
                          v-if="item.originCountry && item.originCountry.iso2"
                          :src="`https://cdn.loglive.io/flags/4x3/${item.originCountry.iso2.toLowerCase()}.svg`"
                        ></v-img>
                      </v-avatar>
                    </template>
                    <span style="font-size: 12px">{{
                      item.originCountry.name
                    }}</span>
                  </v-tooltip>
                </template>

                <template v-slot:[`item.documentationPOD`]="{ item }">
                  <template v-if="item.consigneeProfilePorts.length > 0">
                    {{ item.consigneeProfilePorts[0].name }}
                  </template>
                  <template v-else>
                    {{ "-" }}
                  </template>
                </template>

                <template v-slot:[`item.destinationCountry.name`]="{ item }">
                  <v-tooltip top v-if="order.destinationCountry">
                    <template v-slot:activator="{ on }">
                      <v-avatar size="32" v-on="on">
                        <v-img
                          contain
                          v-if="
                            item.destinationCountry && item.destinationCountry.iso2
                          "
                          :src="`https://cdn.loglive.io/flags/4x3/${item.destinationCountry.iso2.toLowerCase()}.svg`"
                        ></v-img>
                      </v-avatar>
                    </template>
                    <span style="font-size: 12px">{{
                      item.destinationCountry.name
                    }}</span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.incoTerm`]="{ item }">
                  <v-chip
                    small
                    outlined
                    class="mt-1"
                    v-for="term in item.consigneeProfileIncoTerms"
                    :key="term.id"
                    >{{ term.incoTerm }}</v-chip
                  >
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="referenceModal"
          width="400px"
          :fullscreen="$vuetify.breakpoint.mobile"
        >
            <v-card v-if="referenceType" flat>
                <v-toolbar flat color="transparent">
                    <v-toolbar-title> Manage {{ referenceType.label }} </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn text @click="referenceModal = false">X</v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-text-field
                      autofocus
                      v-model="order[referenceType.field]"
                      outlined
                      dense
                      clearable
                      @change="$emit('orderChange')"
                    />
                </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="partyModal"
          width="400px"
          :fullscreen="$vuetify.breakpoint.mobile"
          persistent
        >
          <v-card :loading="loadingRelatedOrganisations" v-if="partyModal">
            <v-toolbar flat color="transparent">
              <v-toolbar-title> Manage {{ partyType }} </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn text @click="(partyModal = false), (searchOrganisation = null)"
                >X</v-btn
              >
            </v-toolbar>
            <v-card-text>
              <v-text-field
                outlined
                dense
                placeholder="Search"
                prepend-inner-icon="search"
                v-model="searchOrganisation"
                clearable
              ></v-text-field>
              <v-list dense style="max-height: 50vh; overflow-y: auto">
                <v-list-item
                  v-for="organisation in filterCustomerOrganisations"
                  :key="organisation.id"
                  @click="setParty(organisation.relatedOrganisation)"
                >
                  <v-list-item-action>
                    <v-avatar
                      v-if="organisation.relatedOrganisation.logo"
                      color="white"
                    >
                      <v-img
                        :src="organisation.relatedOrganisation.logo"
                        contain
                      ></v-img>
                    </v-avatar>
                    <v-avatar v-else color="secondary">
                      <h3>{{ organisation.relatedOrganisation.name.charAt(0) }}</h3>
                    </v-avatar>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ organisation.relatedOrganisation.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      v-if="organisation.relatedOrganisation.alias"
                    >
                      {{ organisation.relatedOrganisation.alias }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="incoTermModal"
          width="400px"
          :fullscreen="$vuetify.breakpoint.mobile"
          peristent
        >
          <v-card>
            <v-toolbar flat color="transparent">
              <v-toolbar-title> Set Inco Term </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn text @click="incoTermModal = false">X</v-btn>
            </v-toolbar>
            <v-card-text>
              <v-list dense style="max-height: 50vh; overflow-y: auto">
                <v-list-item
                  v-for="code in incoTerms"
                  :key="code.id"
                  @click="setIncoTerm(code)"
                  style="height: 45px"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ code.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ code.abbreviation }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="customerSearchModal"
          width="400px"
          :fullscreen="$vuetify.breakpoint.mobile"
          persistent
        >
          <v-card :loading="loadingRelatedOrganisations" v-if="customerSearchModal">
            <v-card-title>
              Search Customer
              <v-spacer></v-spacer>
              <v-btn text @click="customerSearchModal = false">X</v-btn>
            </v-card-title>
            <v-card-text>
              <v-text-field
                placeholder="Search"
                autofocus
                prepend-inner-icon="search"
                v-model="searchCustomer"
                outlined
                dense
                clearable
                hide-details
              ></v-text-field>
              <v-list style="max-height: 50vh; overflow-y: auto" dense>
                <v-list-item
                  v-for="organisation in filterOrganisations"
                  :key="organisation.id"
                  style="height: 50px"
                  @click="setCustomer(organisation)"
                >
                  <v-list-item-action>
                    <v-avatar
                      size="40"
                      v-if="organisation.relatedOrganisation.logo"
                      color="white"
                    >
                      <v-img
                        :src="organisation.relatedOrganisation.logo"
                        contain
                      ></v-img>
                    </v-avatar>
                    <v-avatar size="40" v-else color="secondary">
                      <h3>{{ organisation.relatedOrganisation.name.charAt(0) }}</h3>
                    </v-avatar>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ organisation.relatedOrganisation.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      v-if="organisation.relatedOrganisation.alias"
                    >
                      {{ organisation.relatedOrganisation.alias }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="filterOrganisations.length == 0">
                  <v-list-item-content class="text-center">
                    <span style="font-size: 12px; color: grey"
                      >No organisations found</span
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="shipmentProfileConfig"
          width="800px"
          :fullscreen="$vuetify.breakpoint.mobile"
          peristent
        >
          <v-card>
            <v-toolbar flat color="transparent">
              <v-toolbar-title> Set Profile Configuration </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn text @click="shipmentProfileConfig = false">X</v-btn>
            </v-toolbar>
            <v-card-text v-if="order.consigneeProfile">
              <v-list dense>
                <v-card
            class="my-2 py-2"
            style="background-color: var(--v-greyRaised-base) !important"

            v-for="term in order.consigneeProfile.consigneeProfileIncoTerms" :key="term.id"
             :disabled="order.contractPartyId && term.contractPartyId !== order.contractPartyId"
              @click="setDealTerm(term)"
          >
            <v-card-text class="my-0 py-0">
              <v-row class="pa-1">
                <v-col cols="12" md="6">
                  <v-list dense subheader class="my-0 py-0">
                    <v-subheader style="font-size: 14px">Details</v-subheader>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-action class="mr-1">
                        <v-avatar
                          size="32"
                          :color="
                            term.contractParty &&
                            term.contractParty.logo
                              ? 'white'
                              : 'secondary'
                          "
                        >
                          <v-img
                            v-if="
                              term.contractParty &&
                              term.contractParty.logo
                            "
                            :src="term.contractParty.logo"
                            contain
                          />
                          <h3 v-else-if="term.contractParty">
                            {{ term.contractParty.name.charAt(0) }}
                          </h3>
                          <h3 v-else>-</h3>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-if="term.contractParty"
                        >
                          <span
                            v-if="term.contractParty.alias"
                            style="font-size: 12px; font-weight: 500"
                            >{{ term.contractParty.alias }}</span
                          >
                          <span
                            v-else
                            style="font-size: 12px; font-weight: 500"
                            >{{ term.contractParty.name }}</span
                          >
                        </v-list-item-title>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-else
                        >
                          -
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          Contract Owner
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item style="height: 30px">
                      <v-list-item-content>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                        >
                          {{ term.incoTerm }}
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          INCO Term
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 12px"
                          class="text-wrap"
                          v-if="term.comment"
                        >
                          {{ term.comment }}
                        </v-list-item-title>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 12px"
                          v-else
                        >
                          -
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          Notes
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" md="6">
                  <v-list dense subheader class="my-0 py-0">
                    <v-subheader style="font-size: 14px">Charges</v-subheader>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-action class="mr-1">
                        <v-avatar
                          size="32"
                          :color="
                            term.originParty &&
                            term.originParty.logo
                              ? 'white'
                              : 'secondary'
                          "
                        >
                          <v-img
                            v-if="
                              term.originParty &&
                              term.originParty.logo
                            "
                            :src="term.originParty.logo"
                            contain
                          />
                          <h3 v-else-if="term.originParty">
                            {{ term.originParty.name.charAt(0) }}
                          </h3>
                          <h3 v-else>-</h3>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-if="term.originParty"
                        >
                          <span
                            v-if="term.originParty.alias"
                            style="font-size: 12px; font-weight: 500"
                            >{{ term.originParty.alias }}</span
                          >
                          <span
                            v-else
                            style="font-size: 12px; font-weight: 500"
                            >{{ term.originParty.name }}</span
                          >
                        </v-list-item-title>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-else
                        >
                          -
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          {{ term.originTerm }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle style="font-size: 11px">
                          Origin Charges
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-action class="mr-1">
                        <v-avatar
                          size="32"
                          :color="
                            term.freightParty &&
                            term.freightParty.logo
                              ? 'white'
                              : 'secondary'
                          "
                        >
                          <v-img
                            v-if="
                              term.freightParty &&
                              term.freightParty.logo
                            "
                            :src="term.freightParty.logo"
                            contain
                          />
                          <h3 v-else-if="term.freightParty">
                            {{ term.freightParty.name.charAt(0) }}
                          </h3>
                          <h3 v-else>-</h3>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-if="term.freightParty"
                        >
                          <span
                            v-if="term.freightParty.alias"
                            style="font-size: 12px; font-weight: 500"
                            >{{ term.freightParty.alias }}</span
                          >
                          <span
                            v-else
                            style="font-size: 12px; font-weight: 500"
                            >{{ term.freightParty.name }}</span
                          >
                        </v-list-item-title>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-else
                        >
                          -
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          {{ term.paymentTerm }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle style="font-size: 11px">
                          Freight Charges
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-action class="mr-1">
                        <v-avatar
                          size="32"
                          :color="
                            term.destinationParty &&
                            term.destinationParty.logo
                              ? 'white'
                              : 'secondary'
                          "
                        >
                          <v-img
                            v-if="
                              term.destinationParty &&
                              term.destinationParty.logo
                            "
                            :src="term.destinationParty.logo"
                            contain
                          />
                          <h3 v-else-if="term.destinationParty">
                            {{ term.destinationParty.name.charAt(0) }}
                          </h3>
                          <h3 v-else>-</h3>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-if="term.destinationParty"
                        >
                          <span
                            v-if="term.destinationParty.alias"
                            style="font-size: 12px; font-weight: 500"
                            >{{ term.destinationParty.alias }}</span
                          >
                          <span
                            v-else
                            style="font-size: 12px; font-weight: 500"
                            >{{ term.destinationParty.name }}</span
                          >
                        </v-list-item-title>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-else
                        >
                          -
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          {{ term.destinationTerm }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle style="font-size: 11px">
                          Destination Charges
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
              </v-list>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="assignStockModal" style="height: 98dvh" fullscreen>
          <AssignStock
            :orders="[order]"
            @close="(assignStockModal = false)"
            :refreshKey="refreshKey"
          />
        </v-dialog>
    </div>
</template>
<script>
import TopBanner from "./TopBanner.vue"
import AssignStock from "../../Stock/AssignStock.vue";
import * as CurrencyCodes from "currency-codes";
import getSymbolFromCurrency from "currency-symbol-map";
import ProductBreakdownTable from "../../SystemComponents/ProductBreakdownTable.vue"
// import GmapCustomMarker from "vue2-gmap-custom-marker";
import ContainerTracking from "../../Tracking/ContainerTracking.vue"
// import { getGoogleMapsAPI } from "gmap-vue";
export default {
components: {
    TopBanner,
    ProductBreakdownTable,
    AssignStock,
    // GmapCustomMarker,
    ContainerTracking
},
inject: ['data'],
props: {
    shipment: {
        type: Object,
        required: false
    },
    order: {
        type: Object,
        required: false
    },
    shipmentItems: {
        type: Array,
        required: false
    },
    productSummary: {
        type: Array,
        required: false
    },
    documents: {
        type: Array,
        required: false
    },
    relationships: {
        type: Array,
        required: false
    },
    bookings: {
        type: Array,
        required: false
    },
    stackDates: {
        type: Object,
        required: false
    },
    loadingOrderContents: {
        type: Boolean,
        required: false
    },
    productSummaryHeaders: {
        type: Array,
        required: false
    },
},
data: () => ({
    mapCenter: {lat: 12.322447, lng:  26.7587,},
    zoom: 1.8,
    mapStyle: [
      {
        elementType: "geometry",
        stylers: [
          {
            color: "#f5f5f5",
          },
        ],
      },
      {
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#616161",
          },
        ],
      },
      {
        elementType: "labels.text.stroke",
        stylers: [
          {
            color: "#f5f5f5",
          },
        ],
      },
      {
        featureType: "administrative",
        elementType: "geometry",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "administrative.land_parcel",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#bdbdbd",
          },
        ],
      },
      {
        featureType: "poi",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "geometry",
        stylers: [
          {
            color: "#eeeeee",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#757575",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [
          {
            color: "#e5e5e5",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#9e9e9e",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [
          {
            color: "#ffffff",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#757575",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [
          {
            color: "#c1d6e0",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#616161",
          },
        ],
      },
      {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#9e9e9e",
          },
        ],
      },
      {
        featureType: "transit",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "transit.line",
        elementType: "geometry",
        stylers: [
          {
            color: "#e5e5e5",
          },
        ],
      },
      {
        featureType: "transit.station",
        elementType: "geometry",
        stylers: [
          {
            color: "#eeeeee",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [
          {
            color: "#3e3e3e",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#ffffff",
          },
        ],
      },
    ],
    orderFinancialSetting: {},
    loadingMapMessage: "Getting tracking data...",
    loadingMap: false,
    noTrackingData: false,
    incoTerms: [],
    currencyModal: false,
    currencies: CurrencyCodes.codes(),
    selectedCurrency: null,
    currencyRate: null,
    incoTermModal: false,
    profileKey: 0,
    refreshKey: 0,
    assignStockModal: false,
    orderItem: {},
    orderItemModal: false,
    partyModal: false,
    loadingRelatedOrganisations: false,
    partyType: null,
    referenceType: null,
    referenceModal: false,
    searchCurrency: "",
    searchCustomer: "",
    currentExchangeRate: 1,
    loadingCurrency: false,
    paymentItem: {},
    containers: [],
    events: [],
    containerRoutes: [],
    paymentKey: 8000,
    customerSearchModal: false,
    searchProfiles: null,
    shipmentProfileDialog: false,
    productKey: 0,
    sailingScheduleModal: false,
    loadingShipmentProfiles: false,
    loadingContainers: true,
    contractKey: 12,
    selectedContainer: {},
    paymentModal: false,
    shipmentProfileConfig: false,
    searchOrganisation: null,
    related: {
      data: [],
    },
    allOriginPorts: [],
    allDestinationPorts: [],
    loadingContainerContents: [],
    savingPaymentItem: false,
    shipmentProfiles: {
      data: [],
    },
    orderStatus: [
        {
            name: 'Planned',
            color: '#9d5aa8'
        },
        {
            name: 'Confirmed',
            color: '#007d5c'
        },
        {
            name: 'Awaiting Departure',
            color: '#dc9f44'
        },
        {
            name: 'In Transit',
            color: '#417eaf'
        },
        {
            name: 'Arrived',
            color: '#4c8d4f'
        },
        {
            name: 'Cancelled',
            color: '#cd584f'
        }
    ],
    bookingStatus: [
      { name: "Draft", icon: "edit_note", color: "#F57C00" },
      { name: "Pending", icon: "pending", color: "#009688" },
      { name: "Requested", icon: "verified", color: "#2196F3" },
      { name: "Confirmed", icon: "check_circle_outline", color: "#007d5c" },
      { name: "Ghost", icon: "help", color: "#607D8B" },
      { name: "Cancelled", icon: "cancel", color: "#F44336" },
      { name: "Rejected", icon: "warning", color: "#F44336" },
    ],
    loading: false,
    shipmentProfileHeaders: [
      {
        text: "System Ref",
        value: "systemReference",
        align: "left",
      },
      {
        text: "Status",
        value: "status",
        align: "center",
      },

      {
        text: "Reference",
        value: "reference",
        align: "center",
      },
      {
        text: "Consignee",
        value: "consignee.name",
        align: "center",
      },
      {
        text: "Shipper",
        value: "shipper.name",
        align: "center",
      },
      {
        text: "Forwarder",
        value: "forwarder.name",
        align: "center",
      },
      {
        text: "Origin",
        value: "originCountry.name",
        sortable: false,
        align: "center",
      },
      {
        text: "Loading Ports",
        value: "loadingPorts",
        sortable: false,
        align: "center",
      },
      {
        text: "Destination",
        value: "destinationCountry.name",
        sortable: false,
        align: "center",
      },

      {
        text: "Discharge Ports",
        value: "dischargePorts",
        sortable: false,
        align: "center",
      },
      {
        text: "Final Dest.",
        value: "finalDestinationCity",
        sortable: false,
        align: "center",
        documentName: null,
      },
      {
        text: "Inco Term(s)",
        value: "incoTerm",
        sortable: false,
        align: "center",
      },
    ],
}),
async created() {
    this.getRelations();
    this.incoTerms = await this.$API.getIncoTerms();
},
watch: {
  "data": {
    immediate: true,
    handler(newVal, oldVal) {
        this.setContainerData(newVal)
    }
  },
    productSummary: {
        handler() {
            this.productKey++;
            this.productsLoading = false;
        },
        deep: true
    },
    "order.id": {
      handler: function (val) {
        if (val) {
          this.loadFinancialSettings(val);
        }
      },
      immediate: true,
    },
    "order.orderCustomerId": {
      immediate: true,
      handler(val) {
        if (val) {
          this.getShipmentProfiles();
        }
      },
    },
    "selectedCurrency": {
      handler: function(val) {
        if(val) {
          this.getCurrentRate(this.orderFinancialSetting.baseCurrency, this.selectedCurrency)
        }
      }
    }
},
computed: {
    // google: getGoogleMapsAPI,

    availableParties() {
      let keys = [
        "Shipper",
        "Forwarder",
        "Consignee",
        "Notify Party",
        "Shipping Line",
        "Other",
      ];
      return keys;
    },
    arrivalPort() {
      let arrivalPort = {};
      this.containerRoutes[0].events.forEach((event) => {
        if (event.typeCode == "ARRI" && event.description == 'Vessel arrived') {
          arrivalPort = event;
        }
      });
      return arrivalPort;
    },
    departurePort() {
      let departurePort = {};
      this.containerRoutes[0].events.forEach((event) => {
        if (event.typeCode == "DEPA" && event.description == 'Vessel departed') {
          departurePort = event;
        }
      });
      return departurePort;
    },
    allAllocatedPallets() {
      let total = 0
      if(this.containers.length > 0) {
        this.containers.forEach(container => {
          container.allocatedPallets ? total += container.allocatedPallets : total += 0
        })
      }
      return total
    },
    totalPackedContainers() {
      let total = 0
      if(this.containers.length > 0) {
        this.containers.forEach(container => {
          if(container.totalPallets && container.allocatedPallets && container.totalPallets == container.allocatedPallets) {
            total += 1
          } else {
            total += 0
          }
        })
      }
      return total
    },
    totalInProgressContainers() {
      let total = 0
      if(this.containers.length > 0) {
        this.containers.forEach(container => {
          if(container.allocatedPallets && container.totalPallets && container.allocatedPallets < container.totalPallets && container.allocatedPallets != 0) {
            total += 1
          } else {
            total += 0
          }
        })
      }
      return total
    },
    totalProblematicContainers() {
      let total = 0
      if(this.containers.length > 0) {
        this.containers.forEach(container => {
          if(!container.totalPallets && container.allocatedPallets) {
            total += 1
          } else {
            total += 0
          }
        })
      }
      return total
    },
    totalEmptyContainers() {
      let total = 0
      if(this.containers.length > 0) {
        this.containers.forEach(container => {
          if(!container.totalPallets && (!container.allocatedPallets || container.allocatedPallets == 0)) {
            total += 1
          } else {
            total += 0
          }
        })
      }
      return total
    },
    allTotalPallets() {
      let total = 0
      if(this.containers.length > 0) {
        this.containers.forEach(container => {
          container.totalPallets ? total += container.totalPallets : total += 0
        })
      }
      return total
    },
    averageProgress() {
      let progress = 0
      if(this.containers.length > 0) {
        this.containers.forEach(container => {
          if(container.allocatedProgress) {
            if(typeof container.allocatedProgress == 'string') {
              container.allocatedProgress = parseFloat(container.allocatedProgress)
            }
            container.allocatedProgress ? progress += container.allocatedProgress : progress += 0
          }
        })
      }
      return progress / this.containers.length
    },
    filterCurrencies() {
      let result = this.currencies;
      if (this.searchCurrency) {
        result = result.filter((currency) =>
          currency.toLowerCase().includes(this.searchCurrency.toLowerCase())
        );
      }
      return result;
    },
    filterOrganisations() {
      let result = [...this.related.data] ?? [];
      result = result.filter((x) => x.isActive && x.isCustomer);
      if (this.searchCustomer) {
        result = result.filter(
          (organisation) =>
            organisation.relatedOrganisation.name
              .toLowerCase()
              .includes(this.searchCustomer.toLowerCase()) ||
            (organisation.relatedOrganisation.alias &&
              organisation.relatedOrganisation.alias
                .toLowerCase()
                .includes(this.searchCustomer.toLowerCase()))
        );
      }

      return result;
    },
    filterCustomerOrganisations() {
      let result = [...this.related.data] ?? [];
      if (this.order.orderCustomer) {
        let find = result.find(
          (x) => x.relatedOrganisationId == this.order.orderCustomerId
        );
        if (!find) {
          result.push({
            relatedOrganisation: this.order.orderCustomer,
            relatedOrganisationId: this.order.orderCustomerId,
          });
        }
      }
      if (this.searchOrganisation) {
        result = result.filter(
          (organisation) =>
            organisation.relatedOrganisation.name
              .toLowerCase()
              .includes(this.searchOrganisation.toLowerCase()) ||
            (organisation.relatedOrganisation.alias &&
              organisation.relatedOrganisation.alias
                .toLowerCase()
                .includes(this.searchOrganisation.toLowerCase()))
        );
      }
      result.sort((a, b) =>
        a.relatedOrganisation.name > b.relatedOrganisation.name
          ? 1
          : b.relatedOrganisation.name > a.relatedOrganisation.name
          ? -1
          : 0
      );
      return result;
    },
    filteredProfiles() {
      let result = this.shipmentProfiles.data.filter(
        (x) => x.status == "Approved"
      );
      if (this.search) {
        result = result.filter(
          (x) =>
            (x.systemReference &&
              x.systemReference
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.reference &&
              x.reference
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.buyer &&
              x.buyer.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.forwarder &&
              x.forwarder.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.shipper &&
              x.shipper.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.consignee &&
              x.consignee.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.onBehalfForwarder &&
              x.onBehalfForwarder.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.onBehalfShipper &&
              x.onBehalfShipper.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.onBehalfConsignee &&
              x.onBehalfConsignee.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.consigneeProfileIncoTerms &&
              x.consigneeProfileIncoTerms
                .map((y) => y.incoTerm.toLowerCase())
                .includes(this.searchProfiles.toLowerCase()))
        );
      }
      return result;
    },
},
methods: {
    setParty(organisation) {
      switch (this.partyType) {
        case "Shipper":
          this.order.shipper = organisation;
          this.order.shipperId = organisation.id;
          break;
        case "Consignee":
          this.order.consignee = organisation;
          this.order.consigneeId = organisation.id;
          break;
        case "Forwarder":
          this.order.forwarder = organisation;
          this.order.forwarderId = organisation.id;
          break;
        case "Buyer":
          this.order.buyer = organisation;
          this.order.buyerId = organisation.id;
          break;
        case "First Notify":
          this.order.firstNotify = organisation;
          this.order.firstNotifyId = organisation.id;
          break;
        case "Second Notify":
          this.order.secondNotify = organisation;
          this.order.secondNotifyId = organisation.id;
          break;
        case "Courier Party":
          this.order.courierParty = organisation;
          this.order.courierPartyId = organisation.id;
          break;
      }

      this.partyModal = false;
      this.$emit("orderChange");
    },
    async getContainerSummary(item) {
        this.loadingContainerContents.push(item.id)
        let result = await this.$API.getContainerSummary(item.shipmentId,item.id);
        if(result) {
            this.containers.find(x => x.id == item.id).productSummary = result
          }
        this.loadingContainerContents = this.loadingContainerContents.filter(x => x != item.id)
        return
    },
    async getContainerTracking(item) {
      this.loadingMap = true
      let result = await this.$API.getContainerTracking(item.id)
      if(result) {
        if(result.position && result.position.coordinates) {
          this.mapCenter = {lat: result.position.coordinates[1], lng: result.position.coordinates[0]}
          this.zoom = 2
          this.loadingMapMessage = 'Success!'
          this.containerRoutes.push(result)
          this.loadingMap = false
          this.noTrackingData = false
        } else {
          this.loadingMapMessage = 'Tracking Data unavailable.'
          this.noTrackingData = true
          this.loadingMap = false
        }
      } else {
        this.loadingMapMessage = 'No subscription found. Subscribing to container...'
        let subscription = await this.$API.subscribeContainer(item.id)
        if(subscription) {
          this.loadingMapMessage = 'Successfully subscribed to container. Building Tracking Data...'
          // create a 5 second delay before retry
          await new Promise(resolve => setTimeout(resolve, 5000))
          this.loadingMapMessage = 'Getting tracking data...'
          let retry = await this.$API.getContainerTracking(item.id)
          if(retry) {
            if(retry.position && retry.position.coordinates) {
              this.mapCenter = {lat: retry.position.coordinates[1], lng: retry.position.coordinates[0]}
              this.zoom = 2
              this.loadingMapMessage = 'Success!'
              this.containerRoutes.push(retry)
              this.loadingMap = false
              this.noTrackingData = false
            } else {
              this.loadingMapMessage = 'Tracking Data unavailable.'
              this.noTrackingData = true
              this.loadingMap = false
            }
          } else {
            await new Promise(resolve => setTimeout(resolve, 10000))
            let retryAgain = await this.$API.getContainerTracking(item.id)
            if(retryAgain) {
              if(retryAgain.position && retryAgain.position.coordinates) {
              this.mapCenter = {lat: retryAgain.position.coordinates[1], lng: retryAgain.position.coordinates[0]}
              this.zoom = 2
              this.loadingMapMessage = 'Success!'
              this.containerRoutes.push(retryAgain)
              this.loadingMap = false
              this.noTrackingData = false
            } else {
              this.loadingMapMessage = 'Tracking Data unavailable.'
              this.noTrackingData = true
              this.loadingMap = false
            }
            } else {
            this.loadingMapMessage = 'Could not get tracking data. Please try again later'
            this.noTrackingData = true
            this.loadingMap = false
            }
          }
          // this.containerRoutes.push(subscription)
        }
      }
      return
    },

    getContainerDirection(container) {
      if(container.position && container.position.coordinates) {
        let start = {lat: container.position.coordinates[1], lng: container.position.coordinates[0]}
        if(container.vesselRoute && container.vesselRoute[0]?.route.length > 0) {
          let last = container.vesselRoute[0].route.length - 1
          let end = {lat: container.vesselRoute[0].route[last].lat, lng: container.vesselRoute[0].route[last].lng}
          return this.getRotation(start, end)
        }
      }
    },
    getRotation(start, end) {
      let radians = getAtan2((end.lng - start.lng), (end.lat - start.lat));
      function getAtan2(x, y) {
        return Math.atan2(x, y);
      }
      let direction = radians * (180 / Math.PI);
      direction = direction - 45; //Icon is rotated by 45deg so we need to adjust
      return direction;
    }, 

    setContainerData(data) {
      this.containers = data.containers ? data.containers : []
      this.selectedContainer = this.containers[0]
      this.loadingContainers = false
      this.events = data.events ? data.events : []
      if(this.containers && this.containers.length > 0) {
        this.containers.forEach((container) => {
          container.productSummary = []
          this.getContainerSummary(container)
          this.getContainerTracking(container)
        })
      }
    },
    assignStock() {
      this.assignStockModal = true;
      this.refreshKey++;
    },
    // TODO: Need to bring in this dialog. 
    // addOrderItem(
    //   item = {
    //     quantity: 1,
    //     bookingCargoItems: [],
    //     containerTypeCode: "45R1",
    //     baseUnit: "CT",
    //     containerised: true,
    //     palletised: true,
    //     // index: this.containe.length,
    //     orderCargoItems: [],
    //     tempId: new Date().getTime(),
    //   }
    // ) {
    //   if(localStorage.orderSettings){
    //     let data = JSON.parse(localStorage.orderSettings);
    //     item.palletised = data.palletised;
    //     item.containerised = data.containerised;
    //     item.baseUnit = data.baseUnit;
    //     item.containerTypeCode = data.containerTypeCode;
    //     item.unitPerPallet = data.unitPerPallet;
    //     item.palletPerContainer = data.palletPerContainer;
    //     item.unitPerContainer = data.unitPerContainer;
    //   } else {
    //     item.palletised = true;
    //     item.containerised = true;
    //     item.baseUnit = 'CT';
    //     item.containerTypeCode = '45R1';
    //     item.unitPerPallet = 80;
    //     item.unitPerContainer = 1600;
    //     item.palletPerContainer = 20;
    //   }
    //   this.orderItem = item;
    //   this.orderItemModal = true;
    // },

    async loadFinancialSettings(id) {
      this.orderFinancialSetting = await this.$API.getOrderFinancialSettings(
        id
      );
      this.selectedCurrency = this.orderFinancialSetting.baseCurrency
    },
    async getRelations() {
      this.loadingRelatedOrganisations = true;
      if (
        localStorage["relatedOrganisations_" + this.$store.state.currentOrg.id]
      ) {
        this.related = JSON.parse(
          localStorage[
            "relatedOrganisations_" + this.$store.state.currentOrg.id
          ]
        );
      }
      this.related = await this.$API.getRelationBasic({
      });
      let findCurrentOrg = this.related.data.find(
        (x) => x.relatedOrganisationId == this.$store.state.currentOrg.id
      );
      if (!findCurrentOrg) {
        this.related.data.unshift({relatedOrganisationId: this.$store.state.currentOrg.id, 
          relatedOrganisation: this.$store.state.currentOrg
        });
      }
      localStorage.setItem(
        "relatedOrganisations_" + this.$store.state.currentOrg.id,
        JSON.stringify(this.related)
      );
     this.loadingRelatedOrganisations = false;
    },
    // TODO: The folliwng functions mutate a prop. Emit these changes to the parent and handle there instead.
    removeProfile() {
      this.order.consigneeProfile = null;
      this.order.consigneeProfileId = null;
      this.order.dealTerm = null;
      this.order.dealTermId = null;
      this.profileKey++;
      this.$emit("orderChange");
    },
    removeCustomer() {
      this.booking.orderCustomer = undefined;
      this.booking.orderCustomerId = undefined;
      this.booking.consigneeProfile = undefined;
      this.booking.consigneeProfileId = undefined;
    },
    async getCurrentRate(currencyFrom, currencyTo) {
      this.loadingCurrency = true
      let rate = `${currencyFrom}/${currencyTo}`
      if(currencyFrom === currencyTo) {
        this.currentExchangeRate = 1
      } else {
        let res = await this.$API.getLatestExchangeRate({rate: rate})
        this.currentExchangeRate = parseFloat(res.rate.toFixed(4))
      }
      this.loadingCurrency = false
    },
    async saveOrderFinancialSetting() {
      this.loading = true;
      await this.$API.updateOrderFinancialSettings(this.orderFinancialSetting);
      this.loading = false;
    },
    async setBaseCurrency(currency) {
      this.orderFinancialSetting.baseCurrency = currency;
      this.currencyModal = false;
      this.saveOrderFinancialSetting();
    },
    getSymbol(currencyCode) {
      return getSymbolFromCurrency(currencyCode);
    },
    editReference(type, label) {
      this.referenceType = { field: type, label: label };
      this.referenceModal = true;
    },
    setIncoTerm(term) {
      this.order.incoTerm = term.abbreviation;
      this.incoTermModal = false;
      this.$emit("orderChange");
    },
    async getShipmentProfiles() {
      this.loadingShipmentProfiles = true;
      this.shipmentProfiles = await this.$API.listCustomerConsigneeProfile(
        this.order.orderCustomerId,
        {}
      );
      this.loadingShipmentProfiles = false;
    },
    async savePaymentItem() {
      this.savingPaymentItem = true;
      this.paymentItem.bookingId = this.order.id;
      if (this.paymentItem.id) {
        await this.$API.updateBookingPayment(this.paymentItem);
        this.order.bookingPaymentDetails[find] = this.paymentItem;
      } else {
        let result = await this.$API.createBookingPayment(this.paymentItem);
        this.order.bookingPaymentDetails.push(result);
      }
      this.paymentKey++;
      this.paymentModal = false;
      this.paymentItem = {};
      this.savingPaymentItem = false;
    },
    async setDealTerm(term) {
      this.order.dealTermId = term.id;
      this.order.dealTerm = term;
      this.order.incoTerm = term.incoTerm;
      this.order.freightTerm = term.paymentTerm;
      this.shipmentProfileConfig = false;
      this.order.bookingPaymentDetails = [];
      if(!this.order.contractPartyId){
        this.order.contractPartyId = term.contractPartyId
        this.order.contractParty = term.contractParty
      }
      if (this.order.dealTerm) {
        if (this.order.dealTerm.freightCharge) {
          this.paymentItem = {
            chargeType: "OceanFreight",
            index: this.order.bookingPaymentDetails.length,
            paymentTerm: this.order.dealTerm.paymentTerm,
            paymentParty: this.formatParty(this.order.dealTerm.freightCharge),
            partyId: this.order.dealTerm.freightPartyId,
            party: this.order.dealTerm.freightParty
          };
          await this.savePaymentItem();
        }

        if (this.order.dealTerm.originCharge) {
          this.paymentItem = {
            chargeType: "OriginTerminalHandling",
            index: this.order.bookingPaymentDetails.length,
            paymentTerm: this.order.dealTerm.originTerm,
            paymentParty: this.formatParty(this.order.dealTerm.originCharge),
            partyId: this.order.dealTerm.originPartyId,
            party: this.order.dealTerm.originParty
          };
          await this.savePaymentItem();
        }

        if (this.order.dealTerm.destinationCharge) {
          this.paymentItem = {
            chargeType: "DestinationTerminalHandling",
            index: this.order.bookingPaymentDetails.length,
            paymentTerm: this.order.dealTerm.destinationTerm,
            paymentParty: this.formatParty(
              this.order.dealTerm.destinationCharge
            ),
            partyId: this.order.dealTerm.destinationPartyId,
            party: this.order.dealTerm.destinationParty
            };
          await this.savePaymentItem();
        }
      }
      this.$emit("orderChange");
    },
    getStatusColor(status, type) {
      if(type == 'state') {
        let color = this.bookingStatus.find(x => x.name == status)?.color
        return color ?? 'grey'
      } else {
        let color = this.orderStatus.find(x => x.name == status)?.color
        return color ?? 'grey'
      }
    },
    formatParty(party) {
      switch (party) {
        case "forwarder":
          return "Forwarder";
        case "buyer":
          return "Buyer";
        case "consignee":
          return "Consignee";
        case "firstNotify":
          return "Notify Party";
        case "secondNotify":
          return "Notify Party";
        default:
          return party;
      }
    },
    async setShipmentProfile(profile) {
      let profileDetail = await this.$API.getConsigneeProfile(profile.id);
      this.order.consigneeProfile = profile;
      this.order.consigneeProfileId = profile.id;
      let parties = [
        "shipper",
        "consignee",
        "forwarder",
        "buyer",
        "firstNotify",
        "secondNotify",
        "courierParty",
        "onBehalfShipper",
        "onBehalfConsignee",
      ];
      for (let i = 0; i < parties.length; i++) {
        this.order[parties[i]] = profile[parties[i]];
        this.order[parties[i] + "Id"] = profile[parties[i] + "Id"];
      }
      this.order.originCountry = profile.originCountry;
      this.order.originCountryId = profile.originCountryId;
      this.order.destinationCountry = profile.destinationCountry;
      this.order.destinationCountryId = profile.destinationCountryId;
      if (profileDetail.finalDestinationValue) {
        this.order.finalDestinationValue = profileDetail.finalDestinationValue;
        this.order.finalDestinationCity = profileDetail.finalDestinationCity;
      }

      this.order.regimeCode = profileDetail.regimeCode ? profileDetail.regimeCode : this.order.regimeCode;
      this.order.regime = profileDetail.regime ? profileDetail.regime : this.order.regime;
      this.order.regimeId = profileDetail.regimeId ? profileDetail.regimeId : this.order.regimeId;
      this.order.caProduct = profileDetail.caProduct;
      this.order.isPrivateHaulage = profileDetail.isPrivateHaulage;
      this.order.isRailage = profileDetail.isRailage;
      this.order.isCarrierHaulage = profileDetail.isCarrierHaulage;
      if (profileDetail.consigneeProfileIncoTerms.length == 1) {
        this.setDealTerm(profileDetail.consigneeProfileIncoTerms[0]);
      }
      if (!profile.consigneeProfileProducts) {
        profile.consigneeProfileProducts =
          await this.$API.getConsigneeProfileProducts(profile.id);
      }
      if (!profile.allDestinationPorts) {
        let findDest = profile.consigneeProfilePorts.filter(
          (x) => x.type == "discharge"
        );
        if (findDest.length == 1) {
          this.order.portOfDischargeValue = findDest[0].code;
          this.order.finalDestinationValue = findDest[0].code;
          this.order.portOfDischargeCity = findDest[0].name;
          this.order.finalDestinationCity = findDest[0].name;
        }
      }

      if (!profile.allOriginPorts) {
        let findDest = profile.consigneeProfilePorts.filter(
          (x) => x.type == "origin"
        );
        if (findDest.length == 1) {
          this.order.portOfLoadValue = findDest[0].code;
          this.order.portOfLoadCity = findDest[0].name;
        }
      }
      this.shipmentProfileDialog = false;
      this.contractKey++;
      this.allOriginPorts = await this.$API.getPortsByCountry(
        profile.originCountry.iso2
      );
      this.allDestinationPorts = await this.$API.getPortsByCountry(
        profile.destinationCountry.iso2
      );
      this.$emit("orderChange");
    },
    handleResize() {
        const bottomCard = document.querySelector('.bottomCard');
        function initResize() {
            let top, x, y;
            function mouseDownHandler(e) {
                x = e.clientX;
                y = e.clientY;
                let topNum = window.getComputedStyle(bottomCard).top;
                top = parseInt(topNum);
                document.addEventListener('mousemove', mouseMoveHandler);
                document.addEventListener('mouseup', mouseUpHandler);
            }
            function mouseMoveHandler(e) {
                const dy = e.clientY - y;
                // bottomCard.style.top = `${top + dy}px`;
                // get height of screen
                let screenHeight = window.innerHeight;
                // get 5% of screen height
                const min = screenHeight * 0.005;
                const snapMax = screenHeight * 0.8;
                // change bottomCard top as long as it is within 5% and 95% of screen height
                if (top + dy > min && top + dy < snapMax) {
                    bottomCard.style.top = `${top + dy}px`;
                } else if (top + dy > snapMax) {
                    bottomCard.style.top = `96%`
                }
            }
            function mouseUpHandler() {
                document.removeEventListener('mousemove', mouseMoveHandler);
                document.removeEventListener('mouseup', mouseUpHandler);
            }
            document.addEventListener('mousedown', mouseDownHandler);
        }
        initResize();
    }
}
}
</script>

<style scoped>
* {
  font-size: 12px;
  --darkLayer: var(--v-greyDarker-base) !important;
  --lighterLayer: var(--v-greyRaised-lighten1) !important;
}
.innerContent {
    max-height: calc(100vh - 36px - 30px) !important;
    height: 100%;
    width: 100%;
    contain: content;
}
.tabContent ::v-deep .v-tabs-bar__content {
    background: var(--darkLayer) !important;
}
.bottomCard {
    height: 100vh;
    top: 96%;
    position: absolute;
    contain: content;
    z-index: 2;
    width: 100%;
    background-color: var(--darkLayer);
}
.gutter {
    height: 5px;
    position: absolute;
    z-index: 10;
    top: 0;
    width: 100%;
    background: var(--darkLayer);
    cursor: ns-resize;
}
.gutter:hover {
    background: var(--v-greyRaised-lighten2);
}
.v-list-item {
    min-height: 38px;
    height: 38px;
    padding: 0 0px !important;
}

.toolbars ::v-deep .v-toolbar__content {
  padding: 0;
}
.v-list-item__avatar {
    margin: 0 2px 0 0 !important;
    height: 30px !important;
    min-width: 30px !important;
    width: 30px !important;

}
.v-list-item__action {
    margin: 0 2px 0 0 !important;
    height: 30px !important;
    min-width: 30px !important;
    width: 30px !important;
    display: flex !important;
    place-content: center !important;
}
.v-list-item__title {
    font-size: 0.75rem !important;
    line-height: 1.4 !important;
}

.v-list-item__subtitle {
    font-size: 0.625rem !important;
    opacity: 0.7 !important;
}

.infoCards {
  background: var(--lighterLayer) !important ; 
  contain: content;
  border-radius: 10px;
}

.theMap ::v-deep.gm-style:nth-child(3){
  border: none !important;
  border-color: transparent !important;
}

.pulse {
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgb(0, 125, 92, 0.1);
	transform: scale(1);
	animation: pulsing 2s infinite;
}

@keyframes pulsing {
  0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 125, 92, 0.7);
	}

	50% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 125, 92, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 125, 92, 0);
	}
}

.overlayMap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

</style>