<template>
    <div v-if="!loading" style="height: 100vh; contain: content; background-color: var(--v-greyRaised-base) !important;">
        <div class="topBar">
            <v-btn plain icon class="mr-2" @click="goBack"><v-icon>arrow_back</v-icon></v-btn>
            <span><b>{{viewType == 'shipment' ? 'Shipment' : viewType == 'order' ? 'Order' : viewType == 'booking' ? 'Booking' : viewType == 'vessel' ? 'Vessel' : 'Container'}}</b></span>
            <span style="font-size:12px; color:  #ffffff98;" class="ml-4">{{selectedItem?.vesselVoyage ?? ''}}</span>
            <v-spacer></v-spacer>
            <v-chip small color="danger" :disabled="$store.state.currentOrg.id == 2507" class="mr-2" v-if="brokenContainers" @click="fixContainerTotals()"><b>Fix Pallet Discrepancy</b></v-chip>
        </div>
        <div class="contents">
            <div class="mainContentWrap">
                <v-tabs height="30px" v-model="currentTab" class="tabby">
                    <v-tab v-for="(tab, index) in shipmentTabs" :key="index" :href="`#${tab.value}`">
                        {{ tab.name }}
                    </v-tab>
                </v-tabs>
                <div class="mainContent">
                    <v-tabs-items v-model="currentTab" style="height: inherit; width: 100%;">
                        <v-tab-item style="height: inherit; width: 100%;" value="overview">
                            <Overview @openDrawer="showDrawer" :key="updateOverviewKey" :shipment="shipment" :shipmentItems="tradingItems" :productSummary="shipmentSummaryItems" :documents="documentTypes" :relationships="relationships"
                            :bookings="bookings" :stackDates="stackDates" :selectedType="viewType" :order="order" :orderContents="orderContents" :loadingOrderContents="loadingOrderContents" :loadingContainerContents="loadingContainerContents"/>
                        </v-tab-item>
                        <v-tab-item style="height: inherit; width: 100%;" value="finance">
                            <TraderFinancials :sideDrawer="drawer == 'Finance' && sideDrawer ? true : false" :order="order" :containers="stock" :organisations="related" :loadingOrganisations="loadingRelatedOrganisations"/>
                        </v-tab-item>
                        <v-tab-item style="height: inherit; width: 100%;" value="stock">
                            <AssignStock :orders="[order]" :traderView="true" v-if="!stockSummaryView" />
                            <TraderStock v-if="stockSummaryView" :orders="[order]" :sideDrawer="drawer == 'Stock' && sideDrawer ? true : false"/>
                        </v-tab-item>
                    </v-tabs-items>
                </div>
            </div>
            <div :class="['sideDrawer', { show: ['Finance', 'Stock'].includes(drawer) ? false : sideDrawer }]">
                <div v-if="drawer == 'Calendar'" style="width: 100%; height: inherit;">
                    <div style="width: 100%; display: flex; justify-content: center; height: calc((100% - 48px)*0.45);">
                        <v-date-picker v-model="dateSelect" :events="eventDatesArray" event-color="primary" full-width />
                    </div>
                    <v-subheader style="font-size: 12px; color: grey">Events</v-subheader>
                    <v-divider></v-divider>
                    <div style="contain: content; height: calc((100% - 48px)*0.55); overflow-y: scroll;">
                    <v-list dense subheader style="background: transparent !important">
                        <v-list-item v-if="shipmentTimeline.length ==0">
                            <v-list-item-content class="text-center">
                                <span style="font-size: 12px; color: grey"> No Events
                                </span>
                            </v-list-item-content>
                        </v-list-item>
                        <ul class="events" v-if="shipmentTimeline && shipmentTimeline.length > 0">
                          <li v-for="event in shipmentTimeline" :key="event.id" style="list-style-type: none;"
                            class="my-3">
                            <v-avatar class="mb-1 mr-2" size="10"
                              :color="event.isActual ? 'success' : 'secondary'"></v-avatar>
                            <strong style="font-size: 12px">{{ $Format.formatDate(event.date).dateTime }}</strong>
                            <v-chip outlined style="border: none" small v-if="event.location">
                              <v-avatar size="20" left>
                                <v-img contain
                                  :src="`https://cdn.loglive.io/flags/4x3/${event.location.substring(0, 2).toLowerCase()}.svg`"></v-img>
                              </v-avatar> 
                              <span style="font-size: 12px;">{{ event.location }}</span>
                            </v-chip>
                            <br>
                            <span class="ml-2" style="font-size: 12px">{{ event.description }}</span>
                            <span v-if="event.vesselName" class="ml-2" style="font-size: 12px;">- {{ event.vesselName }} {{ event.voyageNo }}</span>
                          </li>
                        </ul>
                    </v-list>
                    </div>
                </div>
                <div v-if="drawer == 'Actions'" style="width: 100%; height: inherit;">
                    <ShipmentActions v-if="viewType == 'shipment'" :shipment="shipment" :relationships="relationships" :updateKey="actionsKey" @refreshShipmentDocuments="refreshShipmentDocuments" :uploads="documents" :customerId="customerId" :bookings="bookings"/>
                    <OrderActions v-if="viewType == 'order'" :order="order" :relationships="relationships" :updateKey="actionsKey" :orderContents="orderContents" :customerId="customerId"/>
                </div>
                <div v-if="drawer == 'Chat'" style="width: 100%; height: 100%;">
                    <LogliveChat @totalUnreadChanged="totalUnreadChanged" :orderId="order.id.toString()" :order="order" :orderContents="orderContents" :containers="containers" :relationships="relationships"/>
                </div>
            </div>
            <div class="rightNav">
                <v-btn plain small icon class="mb-2" @click="showDrawer('Finance')" :color="sideDrawer && drawer === 'Finance' ? 'primary' : ''" v-if="currentTab == 'finance'">
                    <v-icon>account_balance</v-icon>
                </v-btn>
                <v-btn plain small icon class="mb-2" @click="showDrawer('Stock')" :color="sideDrawer && drawer === 'Stock' ? 'primary' : ''" v-if="currentTab == 'stock'">
                    <v-icon>widgets</v-icon>
                </v-btn>
                <v-divider v-if="currentTab == 'finance' || currentTab == 'stock'" class="mb-2" style="width: 100%;"></v-divider>
                <v-btn plain small icon class="mb-2" @click="showDrawer('Actions')" :color="sideDrawer && drawer === 'Actions' ? 'primary' : '' ">
                    <v-icon>apps</v-icon>
                </v-btn>
                <v-badge overlap class="smallBadge" color="tertiary" :value="totalUnreadChats" :content="totalUnreadChats">
                    <v-btn plain small icon class="mb-2" @click="showDrawer('Chat')" :color="sideDrawer && drawer === 'Chat' ? 'primary' : '' ">
                        <v-icon>chat</v-icon>
                    </v-btn>
                </v-badge>
                <!-- <v-btn plain small icon class="mb-2" @click="showDrawer('Attachments')" :color="sideDrawer && drawer === 'Attachments' ? 'primary' : ''">
                    <v-icon>description</v-icon>
                </v-btn> -->
                <v-btn plain small icon class="mb-2" @click="showDrawer('Calendar')" :color="sideDrawer && drawer === 'Calendar' ? 'primary' : ''">
                    <v-icon>event</v-icon>
                </v-btn>
                <!-- <v-btn plain small icon class="mb-2" @click="showDrawer('Tracking')" :color="sideDrawer && drawer === 'Tracking' ? 'primary' : ''">
                    <v-icon>location_on</v-icon>
                </v-btn>
                <v-btn plain small icon class="mb-2" @click="showDrawer('Notifications')" :color="sideDrawer && drawer === 'Notifications' ? 'primary' : ''">
                    <v-icon>notifications_active</v-icon>
                </v-btn>
                <v-btn plain small icon class="mb-2" @click="showDrawer('Parties')" :color="sideDrawer && drawer === 'Parties' ? 'primary' : ''">
                    <v-icon>groups</v-icon>
                </v-btn> -->
            </div>
        </div>
        <v-dialog v-model="fixTotalPalletsDialog" width="25vw">
            <v-card>
                <v-card-title class="d-flex justify-center">Fix Pallet Discrepancy</v-card-title>
                <v-card-text>
                    <span class="d-flex justify-center text-center" style="width: 100%;">No Total Pallets has been set. In order to correctly determine the correct allocation, we need the Total Pallets.</span>
                    <div class="d-flex align-center justify-center pt-4" style="width: 100%;">Set Total Pallets</div>
                    <div class="d-flex align-center justify-center pt-2" style="width: 100%;">
                        <v-btn plain small outlined class="mr-2" color="primary" icon @click="totalFixNum--"><v-icon>remove</v-icon></v-btn>
                        <div style="max-width: 40px;">
                            <v-text-field v-model="totalFixNum" hide-details dense outlined></v-text-field>
                        </div>
                        <v-btn plain small icon outlined class="ml-2" color="primary" @click="totalFixNum++"><v-icon>add</v-icon></v-btn>
                    </div>
                </v-card-text>
                <v-card-actions class="d-flex justify-center">
                    <v-btn small plain color="error" @click="fixTotalPalletsDialog = false">Cancel</v-btn>
                    <v-btn small color="primary" @click="fixPallets">Fix</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
    <div v-else style=" height: 100%; padding-top: 0; margin-top: 0; background: transparent; " class="text-center">
      <valhalla-loading />
    </div>
</template>

<script>
import Overview from './Overview/Overview.vue';
import ShipmentActions from '../TradingView/SideDrawer/Shipments/Actions.vue'
import OrderActions from '../TradingView/SideDrawer/Orders/Actions.vue'
import TraderFinancials from "./Overview/TraderFinancials.vue";
import AssignStock from "../Stock/AssignStock.vue";
import TraderStock from "./Overview/TraderStock.vue";
import LogliveChat from "../Chat/LogliveChat.vue";
// import DynamicGrid from "../AgGrid/DynamicGrid.vue";
export default {
components: {
    Overview,
    ShipmentActions,
    OrderActions,
    TraderFinancials,
    AssignStock,
    TraderStock,
    LogliveChat,
    // DynamicGrid
},
provide() {
    let data = {}
    Object.defineProperties(data, {
        containers: {
            enumerable: true,
            get: () => this.containers
        },
        events: {
            enumerable: true,
            get: () => this.events
        }
    })
    return {
        data
    }
    },
data: () => ({
    sideDrawer: false,
    currentTab: null,
    totalFixNum: 20,
    selectedItem: null,
    stock: [],
    loading: false,
    stockSummaryView: true,
    drawer: 'Chat',
    shipment: null,
    dateSelect: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    tradingItems: [],
    loadingShipment: false,
    fixTotalPalletsDialog: false,
    documentTypes: [],
    documents: [],
    containers: [],
    stackDates: {},
    updateOverviewKey: 12,
    bookings: [],
    shipmentSummaryItems: [],
    loadingRelatedOrganisations: false,
    related: {
      data: [],
    },
    viewType: 'shipment',
    userScope: null,
    relationships: [],
    events: [],
    onHoldWarningModal: false,
    documentUpdateKey: 12,
    customerId: null,
    orderContents: [],
    loadingOrderContents: false,
    loadingContainerContents: false,
    customEventModal: false,
    resettingTasks: false,
    scope: null,
    totalUnreadChats: 0,
    shipmentTimeline: [],
    actionsKey: 0,
    order: null,
    shipmentTabs: [
        { name: 'Overview', value: 'overview'},
        { name: 'Finance', value: 'finance'},
        { name: 'Stock', value: 'stock' },
        // { name: 'Test', value: 'test' },
        // { name: 'Documents', value: 'documents'},
        // { name: 'Events', value: 'events'},
        // { name: 'Tracking', value: 'tracking' },
        // { name: 'Shipment Profile', value: 'shipmentProfile' }
    ]
}),
watch: {
    currentTab: {
        handler(val) {
            if(val == 'finance') {
                console.log('finance')
            }
        }
    }
},
created() {
    this.setSelectedItem();
    this.load();
    this.getRelations()
},
beforeMount() {

},
mounted() {
},
computed: {
    eventDatesArray() {
        if(this.shipmentTimeline.length == 0){
            return []
        } 
        let result = this.shipmentTimeline.map(x => new Date(x.date).toISOString().substr(0, 10)) ?? []
        return result
    },
    brokenContainers() {
        let status = false
        let containers = JSON.parse(JSON.stringify(this.containers))
        containers.forEach(x => {
            if(!x.totalPallets) {
                status = true
            }
        })
        return status
    }
},
methods: {
    fixContainerTotals() {
        this.fixTotalPalletsDialog = true
    },
    totalUnreadChanged(total) {
        this.totalUnreadChats = total
    },
    async fixPallets() {
        this.fixTotalPalletsDialog = false
        let brokenContainers = this.containers.filter(x => !x.totalPallets).map(x => x.id)
        let obj = {
            brokenContainers,
            totalPallets: this.totalFixNum
        }
        let result = await this.$API.fixContainerTotals(obj)
        if(result) {
            this.$message.success('Successfully fixed pallets')
            this.getOrderContainers(this.order.id)
        } else {
            this.$message.error('Failed to fix pallets')
        }
        
    },
    async setAccessState() {
      let access = await this.$API.getUserScope({ path: '/trading/summary/:hash', module: 'TradingSummary' })
      this.userScope = access
      await this.$store.dispatch("setAccessState", access);
    },
    checkStockStatus() {
        this.containers.forEach(x => {
        })
    },
    async getShipment(id) {
        let shipment = await this.$API.getSingleShipment(id);
        return shipment
    },
    async getShipmentProductSummary(id) {
        let shipmentSummaryItems = await this.$API.getShipmentProductSummary(id);
        return shipmentSummaryItems
    },
    async getShipmentDocumentTypes(id) {
        let documentTypes = await this.$API.getShipmentDocumentTypes(id);
        return documentTypes
    },
    async getShipmentRelationships(id) {
      let relationships = await this.$API.getShipmentRelationships(id)
      this.onHoldWarningModal = this.relationships.some(x=>x.organisationRelationship.clientStatus == 'ON HOLD')
      if(this.relationships.length>0){
        this.customerId = this.relationships[0].customerId
      }
      return relationships
    },
    async getRelations() {
        this.loadingRelatedOrganisations = true;
        if ( localStorage["relatedOrganisations_" + this.$store.state.currentOrg.id] ) {
          this.related = JSON.parse( localStorage[ "relatedOrganisations_" + this.$store.state.currentOrg.id ] );
        }
        this.related = await this.$API.getRelationBasic({});
        let findCurrentOrg = this.related.data.find( (x) => x.relatedOrganisationId == this.$store.state.currentOrg.id );
        if (!findCurrentOrg) {
          this.related.data.unshift({relatedOrganisationId: this.$store.state.currentOrg.id, relatedOrganisation: this.$store.state.currentOrg });
        }
        localStorage.setItem( "relatedOrganisations_" + this.$store.state.currentOrg.id, JSON.stringify(this.related) );
        this.loadingRelatedOrganisations = false;
    },
    async getStock() {
      this.stock = await this.$API.getOrderContainersBasic(this.order.id);
      this.stock.forEach((x) => {
        x.assignedProgress = (x.assignedStock / x.totalPallets) * 100;
      });
    },

    async refreshShipmentDocuments() {
      this.$confirm(
        "Are you sure you want to regenerate all documents for this file? This action cannot be reversed.",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      ).then(async () => {
        this.shipment.fileStatus = "In Progress";
        let result = await this.$API.refreshShipmentDocuments(this.shipment.id);
        this.$message.success("Successfully started regenerating documents");
      });
    },
    async getBookings(fileId) {
        this.bookings = await this.$API.getShipmentBookings(fileId);
    },
    async getContents(systemRef) {
        this.loadingOrderContents = true;
        let contents = await this.$API.getContentSummary({
            params: {
                type: 'order',
                systemReference: systemRef
            }
        });
        this.orderContents = contents
        this.loadingOrderContents = false
    },
    async getShipmentDocuments() {
      this.documents = await this.$API.getShipmentDocuments(this.shipment.id);
      this.documentUpdateKey++
    
    },
    async getOrder(systemRef) {
        let order = await this.$API.getOrderDetail(systemRef);
        return order
    },
    async getShipmentEvents() {
        let events = await this.$API.getShipmentEvents(this.shipment.id);
        this.events = events
    },
    async getOrderContainers(id) {
        this.loadingContainers = true
        let containers = await this.$API.getOrderContainers(id);
        containers.forEach(x => {
           if(!x.loadOutInstructions) {
               x.loadOutInstructions = []
           }
        });
        this.containers = containers
        this.checkStockStatus()
        this.updateOverviewKey++
    },
    async getVesselDetails(ref) {
      let voyageInfo = await this.$API.getShipmentVesselVoyage(ref);
      if (voyageInfo) {
        if (new Date(voyageInfo.etd) < new Date()) {
          let difference = this.$Format.calculateDays(new Date(this.shipment.etd), new Date())
          let shipmentTotal = this.$Format.calculateDays(new Date(this.shipment.etd), new Date(this.shipment.eta))
          difference = difference > shipmentTotal ? shipmentTotal : difference
          voyageInfo.progress = difference > 0 ? difference : 0
        } else {
          voyageInfo.progress = 0
        }
        voyageInfo.vesselVoyageLegs.forEach(x => {
          let shipmentTotal = this.$Format.calculateDays(new Date(x.etd), new Date(x.eta))
          let difference = this.$Format.calculateDays(new Date(x.etd), new Date())
          difference = difference > shipmentTotal ? shipmentTotal : difference
          if (new Date(x.etd) < new Date()) {
            x.progress = difference > 0 ? difference : 0
          } else {
            x.progress = 0
          }
        })
      }
      this.shipment.voyageInfo = voyageInfo
      this.stackDates = this.shipment.voyageInfo.rmsStack
    },
    async load() {
        // TODO: Get Jarryd to help with implementing the correct permissions
        // await this.setAccessState()
        // this.scope = await this.$API.authoriseScope({
        //   type: "trading"
        // })
        // if(typeof this.scope.tabs == 'string'){
        //     this.scope.tabs = JSON.parse(this.scope.tabs)
        // }
        let shortcut = null
        if(sessionStorage.getItem('TradingShortcut')) {
            let item = JSON.parse(sessionStorage.getItem('TradingShortcut'))
            item.shortcut ? shortcut = item.shortcut : null
        }
        let type = sessionStorage.getItem('tradingType')
        this.viewType = type
        let tradingItem = JSON.parse(sessionStorage.getItem('tradingItem'))
        let ref = tradingItem.shipmentReference ?? null;
        let systemRef = tradingItem.systemReference ?? null;
        let shipmentId = tradingItem.shipmentId ?? tradingItem.shipmentFile?.shipmentId ?? null
        this.tradingItem = tradingItem
        this.loading = true;
        if(type == 'shipment') {
            if(shipmentId) {
                let promises = [
                    { promise: this.getShipment(ref), error: "Failed to load shipment data", key: 'shipment' },
                    { promise: this.getShipmentProductSummary(shipmentId), error: "Failed to load product data", key: 'shipmentSummaryItems' },
                    { promise: this.getShipmentDocumentTypes(shipmentId), error: "Failed to load document data", key: 'documentTypes' },
                    { promise: this.getShipmentRelationships(shipmentId), error: "Failed to load relationship data", key: 'relationships' },
                ];
                let results = await Promise.allSettled(promises.map(p => p.promise));
                results.forEach((result, index) => {
                    if (result.status == "rejected") {
                        this.$message.error(promises[index].error);
                    } else {
                        this[promises[index].key] = result.value;
                    }
                });
                if (this.shipment?.eta) {
                    await this.getVesselDetails(ref);
                    this.getShipmentDocuments();
                }
                if (this.shipment?.shipmentFileId) {
                    await this.getBookings(this.shipment.shipmentFileId);
                }
            }
            this.actionsKey++
            this.loading = false;
        } else if (type == 'order') {
            let promises = [
                { promise: this.getOrder(systemRef), error: "Failed to load Order detail", key: 'order'},
            ]
            if(ref) {
                promises.push({ promise: this.getShipment(ref), error: "Failed to load shipment data", key: 'shipment' })
            }

            let results = await Promise.allSettled(promises.map(p => p.promise));
            results.forEach((result, index) => {
                if (result.status == "rejected") {
                    this.$message.error(promises[index].error);
                } else {
                    this[promises[index].key] = result.value;
                }

            });
            if(this.order?.id) {
                this.getStock();
                if(shortcut && shortcut == 'container') {
                    await this.getOrderContainers(this.order.id)
                } else {
                    this.getOrderContainers(this.order.id)
                }
            }
            if(this.shipment?.eta) {
                await this.getVesselDetails(ref);
                this.getShipmentEvents();
                this.getContents(systemRef)
            }
            this.actionsKey++
            this.checkStockStatus()
            this.loading = false;
        }
    },
    goBack() {
        this.$router.go(-1);
    },
    setSelectedItem() {
        if(sessionStorage.getItem('tradingItem')) {
            this.selectedItem = JSON.parse(sessionStorage.getItem('tradingItem'));

        }
    },

    showDrawer(view) {
        if (this.sideDrawer) {
            if(this.drawer !== view) {
                this.sideDrawer = false;
                setTimeout(() => {                  
                    this.drawer = view;
                    this.sideDrawer = true;
                }, 200);
            } else {
                this.sideDrawer = false;
            }
        } else {
            this.drawer = view;
            this.sideDrawer = true;
        }
    },
}

}
</script>

<style scoped>
* {
    --gutters: 0.1rem;
    --toolBarHeight: 36px;
    --tabHeight: 30px;
    --darkLayer: var(--v-greyDarker-base) !important;
}

.topBar {
    height: var(--toolBarHeight);
    width: 100%;
    display: flex;
    align-items: center;
    background-color: var(--v-greyBase-base) !important;
}
.contents {
    display: flex;
    width: 100%;
    height: calc(100% - var(--toolBarHeight));
}
.mainContentWrap {
    background-color: var(--v-greyDarker-base) !important;
    height: 100%;
    min-height: 100%;
    width: 100%;
    min-width: 50%;
    max-width: 100%;
    position: relative;
}
.mainContentTabs {
    height: var(--tabHeight) !important;
}
.mainContent {
    max-height: calc(100vh - var(--toolBarHeight) - var(--tabHeight));
    height: 100%;
    width: 100%;
}
.sideDrawer {
    background-color: var(--darkLayer) !important;
    height: calc(100% - 36px) !important;
    min-height: calc(100% - 36px) !important;
    width: 350px;
    box-shadow: -10px 0 10px -5px rgba(0, 0, 0, 0.5);
    min-width: 350px;
    max-width: 50%;
    z-index: 10;
    right: -350px;
    top: 36px;
    position: absolute;
    transition: right 0.2s ease-in-out;
}

.sideDrawer.show {
    right: 36px;
}

.rightNav {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--darkLayer) !important;
    width: var(--toolBarHeight);
    min-width: var(--toolBarHeight);
    z-index: 20;
    padding: 4px 0 0 0;
}

.tabby ::v-deep.theme--dark.v-tabs .v-tabs-bar, .tabby ::v-deep .v-tabs-bar__content, .tabby ::v-deep .v-tab {
    background: var(--v-greyDarker-base) !important;
    border-radius: 0px;
}

.smallBadge ::v-deep .v-badge__badge {
    scale: 0.7 !important;
    inset: auto auto calc(100% - 12px) calc(100% - 15px) !important;
}




</style>