<template>
    <div style="height: inherit; width: 100%; display: flex;" >
        <div class="leftSections">
            <v-list dense class="listLeft">
                <v-list-item dense class="listItemLeft" v-if="shipment && shipment.id && selectedType == 'shipment'" @click="viewShipmentDetails()">
                    <v-list-item-avatar>
                        <v-icon small>directions_boat</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ shipment.vesselName }} - {{ shipment.voyage }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            {{ shipment.shipmentFile?.fileNumber ?? shipment.fileNumber ?? ''}}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click="changeView('order', order)" dense class="listItemLeft" :class="selectedType == 'shipment' ? 'ml-3' : ''">
                    <v-list-item-avatar>
                        <v-icon small>account_tree</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ order.orderNo }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            {{ selectedType == 'order' ? 'Order' : selectedType == 'booking' ? 'Booking' : ''}}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-for="(container, index) in containers" @click="changeView('container', container)" :key="index" dense class="listItemLeft " :class="selectedType == 'shipment' ? 'ml-6' : selectedType == 'container' ? 'ml-0' : 'ml-3'" :disabled="!container.containerNo">
                    <v-list-item-avatar>
                      <v-icon v-if="!container.totalPallets && container.allocatedPallets" color="danger">error_outline</v-icon>
                      <v-progress-circular v-else-if="container.totalPallets && container.allocatedPallets" :value="container.allocatedProgress" size="20"
                        :color=" container.totalPallets == 0 ? 'grey' : container.allocatedProgress < 20 ? 'red' : container.allocatedProgress < 50 ? 'orange' : container.allocatedProgress < 100 ? 'blue' : 'success' " />
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{container.containerNo ?? container.ctoNo ?? ''}}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            {{container.containerNo ? container.ctoNo : 'No Container Number found.'}}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </div>
        <div class="rightSection">
            <v-row no-gutters style="height: 100%;">
                <v-col cols="12" sm="12" md="9" lg="10" :key="updateViewKey">
                    <div class="contentSection">
                        <OverviewShipment v-if="contentView == 'shipment' && shipment"
                            :shipment="shipment"
                            :shipmentItems="shipmentItems"
                            :productSummary="productSummary"
                            :documents="documents"
                            :relationships="relationships"
                            :bookings="bookings"
                            :stackDates="stackDates"
                            @viewShipmentDetails="viewShipmentDetails"
                            @openDocumentSummary="openDocumentSummary"
                        />
                        <OverviewOrder v-else-if="contentView == 'order' && order"
                            :shipment="shipment"
                            :order="order"
                            :shipmentItems="shipmentItems"
                            :productSummary="orderContents"
                            :documents="documents"
                            :relationships="relationships"
                            :bookings="bookings"
                            :stackDates="stackDates"
                            :loadingOrderContents="loadingOrderContents"
                            :productSummaryHeaders="productSummaryHeaders"
                            @openDrawer="$emit('openDrawer', $event)"
                        />
                        <OverviewContainer v-else-if="contentView == 'container' && (order || selectedBooking) && selectedContainer"
                            :shipment="shipment"
                            :order="order"
                            :booking="selectedBooking"
                            :container="selectedContainer"
                            :productSummary="selectedContainer.productSummary"
                            :documents="documents"
                            :relationships="relationships"
                            :bookings="bookings"
                            :stackDates="stackDates"
                            :loadingContainerContents="loadingContainerContents"
                            :productSummaryHeaders="productSummaryHeaders"
                            @openDrawer="$emit('openDrawer', $event)"
                        />
                    </div>
                </v-col>
                <v-col cols="12" sm="12" md="3" lg="2" style="height: 100%; border-left: solid 1px var(--v-greyRaised-lighten2);">
                    <div style="height: calc(100vh - 66px); overflow-y: scroll;width: 100%; flex-direction: column;" class="d-flex pa-2">
                      <v-card v-if="order.allowBooking" class="infoCards mb-2" flat style="min-height: initial; height: 100%;">
                        <v-subheader style="height: 30px;" class="px-2">
                          <v-icon small class="mr-2">location_on</v-icon>
                          Booking Details
                        </v-subheader>
                        <v-divider></v-divider>
                        <v-list-item @click="!order.bookingRequestStatus?submitBookingRequest():null">
                          <v-list-item-action>
                            <v-icon small color="grey" >send</v-icon >
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title v-if="!order.bookingRequestStatus">
                              Submit booking request
                            </v-list-item-title>
                            <v-list-item-title v-else>Booking Request
                              </v-list-item-title>
                            <!-- <v-list-item-subtitle> Origin Country </v-list-item-subtitle> -->
                          </v-list-item-content>
                          <v-list-item-action style="width: 80px !important;">
                            <v-chip class="px-2" small v-if="order.bookingRequestStatus" :color="order.bookingRequestStatus == 'SUBMITTED'?'blue':order.bookingRequestStatus=='ACCEPTED'?'success':'red'">{{ order.bookingRequestStatus }}</v-chip>
                            <v-progress-circular color="blue" indeterminate size="20" v-if="submittingBookingRequest"></v-progress-circular>
                          </v-list-item-action>
                        </v-list-item>
                        <v-list-item v-if="order.bookingRequestStatus && order.bookingRequestStatus == 'ACCEPTED'">
                          <v-list-item-action>
                            <v-icon color="grey" small >grade</v-icon >
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              Booking Status
                            </v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action style="width: 80px !important;">
                            <v-chip class="px-2" :color="statusColor(order.state)" small>
                              {{ order.state }}
                            </v-chip>
                          </v-list-item-action>
                        </v-list-item>
                        <v-list-item v-if="order.bookingHandler">
                          <v-list-item-action >
                            <v-avatar size="24" :color="order.bookingHandler.logo?'white':'secondary'">
                            <v-img :src="order.bookingHandler.logo" v-if="order.bookingHandler.logo" contain> </v-img>
                            <h3 v-else> {{ order.bookingHandler.name.charAt(0) }} </h3>
                            </v-avatar>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ order.bookingHandler.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              Booking Handler
                            </v-list-item-subtitle>
                          </v-list-item-content>
 
                          <v-list-item-action style="width: 40px !important;">
                            <v-tooltip top v-if="order.user">
                              <template v-slot:activator="{ on }">
                                <v-avatar style="cursor: pointer" size="24" v-on="on" :color="order.user.avatar ? 'white' : 'secondary'" >
                                  <v-img v-if="order.user.avatar" contain :src="order.user.avatar" ></v-img>
                                  <h3 v-else> {{ order.user.firstname.charAt(0) }} </h3>
                                </v-avatar>
                              </template>
                              <span style="font-size: 12px" >Assigned User: {{ order.user.firstname }} {{ order.user.surname }}</span >
                            </v-tooltip>
                          </v-list-item-action>
                        </v-list-item>
                      </v-card>
                      <v-card class="infoCards mb-2" flat id="LoadingPoints" style="min-height: initial; height: 100%;" v-if="selectedContainer && milestones.length > 0 && contentView == 'container'">
                          <v-subheader style="height: 30px;" class="px-2">
                              <v-icon small class="mr-2">pin_drop</v-icon>Loading Points
                          </v-subheader>
                          <v-divider></v-divider>
                          <v-timeline dense class="pt-1 timelineSmall">
                            <v-timeline-item color="tertiary" small fill-dot left v-for="(item, index) in milestones" :key="index">
                              <v-list-item dense class="pl-2">
                                  <div style="flex-direction: column; width: 50px; scale: 0.8;" class="pr-2 d-flex justify-center align-center">
                                      <span><b>{{ item.date ? $Format.formatDate(item.date).shortDate : '' }}</b></span>
                                      <span>{{ item.date ? $Format.formatDate(item.date).time : '' }}</span>
                                  </div>
                                  <v-list-item-content>
                                      <v-list-item-title v-if="item.poiId || item.location">
                                          {{ item.poiId ? item.poi?.name ? item.poi.name : item.poi.friendlyName ? item.poi.friendlyName : '' : item.location ? item.location : '' }}
                                      </v-list-item-title>
                                      <v-list-item-title v-else>
                                        {{$Format.milestoneTypeFormat(item.type) ?? '-'}}
                                      </v-list-item-title>
                                      <v-list-item-subtitle v-if="item.poiId || item.location">
                                          {{$Format.milestoneTypeFormat(item.type) ?? '-'}}
                                      </v-list-item-subtitle>
                                  </v-list-item-content>
                              </v-list-item>
                            </v-timeline-item>
                          </v-timeline>
                        </v-card>
                        <v-card class="infoCards mb-2" flat id="Services" style="min-height: initial;" v-if="selectedContainer && contentView == 'container' && selectedContainer.temporaryProducts?.length > 0">
                          <v-subheader style="height: 30px;" class="px-2">
                              <v-icon small class="mr-2">wifi_tethering</v-icon>Services
                          </v-subheader>
                          <v-divider></v-divider>
                          <v-list dense>
                            <v-list-item v-for="(service, index) in selectedContainer.temporaryProducts" :key="index">
                              <v-list-item-action>
                                <v-icon small :color="$Format.getServicesIcons(service.product).color">{{$Format.getServicesIcons(service.product).icon}}</v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>{{ service.product ?? '-'}}</v-list-item-title>
                                <v-list-item-subtitle>{{ service.monitoringService ?? '-' }}</v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card>
                        <v-card class="infoCards mb-2 pb-1" flat id="Transport">
                          <v-subheader style="height: 30px;" class="px-2">
                                <v-icon small class="mr-2">route</v-icon>Transport
                            </v-subheader>
                            <v-divider></v-divider>
                            <v-list-item dense style="height: 60px !important;">
                              <v-list-item-action v-if="!order.shippingLine" :disabled="order.bookingRequestStatus=='ACCEPTED' && order.bookingHandler != null">
                                <v-icon color="secondary">directions_boat</v-icon>
                              </v-list-item-action>
                              <v-list-item-avatar v-else>
                                <v-avatar color="white" size="24" v-if="order.shippingLine">
                                  <v-img
                                    v-if="order.shippingLine && order.shippingLine.logo"
                                    :src="order.shippingLine.logo"
                                    contain
                                  ></v-img>
                                </v-avatar>
                              </v-list-item-avatar>
                              <v-list-item-content v-if="order.vessel" :disabled="order.bookingRequestStatus=='ACCEPTED' && order.bookingHandler != null">
                                <v-list-item-title>
                                  {{ order.vessel.name }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  <u style="cursor: pointer" @click="voyageNumberModal = true" v-if="order.mainCarriageConveyanceNumber" >Voyage No: {{ order.mainCarriageConveyanceNumber }}</u>
                                  <u style="cursor: pointer" @click="voyageNumberModal = true" v-else >No Voyage No</u>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle>Vessel/Voyage</v-list-item-subtitle>
                              </v-list-item-content>
                              <v-list-item-content v-else>
                                <span style="font-size: 16px">Add Vessel</span>
                              </v-list-item-content>
                              <v-list-item-action>
                                <v-row justify="center">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                      <v-btn :disabled="order.bookingRequestStatus=='ACCEPTED' && order.bookingHandler != null" v-on="on" color="teal" icon @click="openSailingSchedules()" >
                                        <v-icon>event</v-icon></v-btn >
                                    </template>
                                    <span style="font-size: 12px">Search Sailing Schedules</span>
                                  </v-tooltip>
                                </v-row>
                              </v-list-item-action>
                            </v-list-item>
                            <v-list-item dense @click="shippingLineModal = true" :disabled="order.bookingRequestStatus=='ACCEPTED' && order.bookingHandler != null">
                              <v-list-item-action v-if="!order.shippingLine">
                                <v-icon color="secondary">directions_boat</v-icon>
                              </v-list-item-action>
                              <v-list-item-avatar v-else>
                                <v-avatar color="white" size="24" v-if="order.shippingLine">
                                  <v-img v-if="order.shippingLine && order.shippingLine.logo" :src="order.shippingLine.logo" contain></v-img>
                                </v-avatar>
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title v-if="order.shippingLine">{{ order.shippingLine.name }}</v-list-item-title>
                                <v-list-item-title v-else>-</v-list-item-title>
                                <v-list-item-subtitle>Shipping Line</v-list-item-subtitle>
                              </v-list-item-content>
                          
                            </v-list-item>
                            <v-list-item dense v-if="!order.vessel">
                              <v-list-item-action>
                                <v-icon :color="order.eta ? 'success' : 'secondary'" >today</v-icon >
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title v-if="order.plannedWeek">
                                  Week {{ order.plannedWeek }}
                                </v-list-item-title>
                                <v-list-item-title v-else> - </v-list-item-title>
                                <v-list-item-subtitle>
                                  Planned Week
                                </v-list-item-subtitle>
                              </v-list-item-content>
                              <v-list-item-action>
                                <v-container>
                                  <v-row justify="center">
                                    <v-autocomplete :items="weekNumbers" v-model="order.plannedWeek" hide-details outlined dense  @input="$emit('orderChange')"></v-autocomplete>
                                  </v-row>
                                </v-container>
                              </v-list-item-action>
                            </v-list-item>
                        
                            <v-list-item dense v-if="!order.vessel">
                              <v-list-item-action>
                                <v-icon :color="order.eta ? 'success' : 'secondary'" >today</v-icon >
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title v-if="order.plannedYear">
                                  Week {{ order.plannedYear }}
                                </v-list-item-title>
                                <v-list-item-title v-else> - </v-list-item-title>
                                <v-list-item-subtitle>
                                  Planned Year
                                </v-list-item-subtitle>
                              </v-list-item-content>
                              <v-list-item-action>
                                <v-container>
                                  <v-row justify="center">
                                    <v-autocomplete :items="[(new Date().getFullYear()), (new Date().getFullYear()+1)]" v-model="order.plannedYear" hide-details outlined dense    @input="$emit('orderChange')"></v-autocomplete>
                                  </v-row>
                                </v-container>
                              </v-list-item-action>
                            </v-list-item>
                            <v-row v-if="order.vessel" no-gutters>
                                <v-col cols="12" sm="6">
                                  <v-menu :disabled="order.bookingRequestStatus=='ACCEPTED' && order.bookingHandler != null"
                                    :close-on-content-click="true"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-list-item v-on="on">
                                        <v-list-item-action>
                                          <v-icon :color="order.etd ? 'success' : 'secondary'" >today</v-icon >
                                        </v-list-item-action>
                                        <v-list-item-content>
                                          <v-list-item-title v-if="order.etd">
                                            {{ $Format.formatDate(order.etd).isoFormat }}
                                          </v-list-item-title>
                                          <v-list-item-title v-else> - </v-list-item-title>
                                          <v-list-item-subtitle> ETD </v-list-item-subtitle>
                                        </v-list-item-content>
                                      </v-list-item>
                                    </template>
                                    <v-date-picker
                                      v-model="order.etd"
                                      no-title
                                      scrollable
                                      @change="$emit('orderChange')"
                                    >
                                    </v-date-picker>
                                  </v-menu>
                                </v-col>
                                <v-col cols="12" sm="6">
                                  <v-menu :disabled="order.bookingRequestStatus=='ACCEPTED' && order.bookingHandler != null"
                                    :close-on-content-click="true"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-list-item v-on="on">
                                        <v-list-item-action>
                                          <v-icon :color="order.eta ? 'success' : 'secondary'" >today</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                          <v-list-item-title v-if="order.eta">
                                            {{ $Format.formatDate(order.eta).isoFormat }}
                                          </v-list-item-title>
                                          <v-list-item-title v-else> - </v-list-item-title>
                                          <v-list-item-subtitle> ETA </v-list-item-subtitle>
                                        </v-list-item-content>
                                      </v-list-item>
                                    </template>
                                    <v-date-picker
                                      v-model="order.eta"
                                      no-title
                                      scrollable
                                      @change="$emit('orderChange')"
                                    >
                                    </v-date-picker>
                                  </v-menu>
                                </v-col>
                            </v-row>
                        </v-card>
                        <v-card class="infoCards mb-2 pb-1" flat id="StackDate" v-if="order.stackDate">
                          <v-subheader style="height: 30px;" class="px-2">
                            <v-icon small class="mr-2">widgets</v-icon>Stacks
                          </v-subheader>
                          <v-divider></v-divider>
                          <v-list-item style="height: 60px !important;">
                            <v-list-item-action>
                            <v-icon small>anchor</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ order.stackDate.terminal }}
                            </v-list-item-title>
                            <v-list-item-subtitle>Planned Loads: {{ order.stackDate.plannedLoads }}</v-list-item-subtitle>
                            <v-list-item-subtitle>Planned Discharges: {{ order.stackDate.plannedDischarges }}</v-list-item-subtitle>
                          </v-list-item-content>
                          </v-list-item>
                          <v-divider></v-divider>
                          <v-subheader style="height: 30px; font-size: 12px" class="px-2"><v-icon small class="mr-2" color="amber">widgets</v-icon>Dry Stacks</v-subheader>
                          <v-divider></v-divider>
                          <v-list-item >
                          <v-list-item-content class="pl-2">
                            <v-list-item-title>
                              {{ $Format.formatDate(order.stackDate.dryStackStart).dayMonCommaYear }}
                            </v-list-item-title>
                            <v-list-item-subtitle>Stacks Open</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action style="width: 60px !important;">
                            <v-chip class="px-2" small :color="order.stackDate.dryStackStartConfirmed?'tertiary':'deep-orange'">{{ order.stackDate.dryStackStartConfirmed?'Firm':'Provisional' }}</v-chip>
                          </v-list-item-action>
                          </v-list-item>
                      
                          <v-list-item>
                          <v-list-item-content class="pl-2">
                            <v-list-item-title>
                              {{ $Format.formatDate(order.stackDate.dryStackFinish).dayMonCommaYear }}
                            </v-list-item-title>
                            <v-list-item-subtitle> Stacks Close</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action style="width: 60px !important;">
                            <v-chip class="px-2" small :color="order.stackDate.dryStackFinishConfirmed?'tertiary':'deep-orange'">{{ order.stackDate.dryStackFinishConfirmed?'Firm':'Provisional' }}</v-chip>
                          </v-list-item-action>
                          </v-list-item>
                      
                          <v-divider></v-divider>
                          <v-subheader style="height: 30px; font-size: 12px" class="px-2"><v-icon small class="mr-2" color="blue">ac_unit</v-icon>Reefer Stacks</v-subheader>
                          <v-divider></v-divider>
                      
                          <v-list-item>
                          <v-list-item-content class="pl-2">
                            <v-list-item-title>
                              {{ $Format.formatDate(order.stackDate.reeferStackStart).dayMonCommaYear }}
                            </v-list-item-title>
                            <v-list-item-subtitle>Stacks Open</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action style="width: 60px !important;">
                            <v-chip class="px-2" small :color="order.stackDate.reeferStackStartConfirmed?'tertiary':'deep-orange'">{{ order.stackDate.reeferStackStartConfirmed?'Firm':'Provisional' }}</v-chip>
                          </v-list-item-action>
                          </v-list-item>
                          <v-list-item>
                          <v-list-item-content class="pl-2 py-0">
                            <v-list-item-title>
                              {{ $Format.formatDate(order.stackDate.reeferStackFinish).dayMonCommaYear }}
                            </v-list-item-title>
                            <v-list-item-subtitle>Stacks Close</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action style="width: 60px !important;">
                            <v-chip class="px-2" small :color="order.stackDate.reeferStackFinishConfirmed?'tertiary':'deep-orange'">{{ order.stackDate.reeferStackFinishConfirmed?'Firm':'Provisional' }}</v-chip>
                          </v-list-item-action>
                          </v-list-item>
                        </v-card>
                        <v-card class="infoCards" flat id="CourierWaybill" style="min-height: 388px; height: 100%;">
                          <v-subheader style="height: 30px;" class="px-2">
                              <v-icon small class="mr-2">approval</v-icon>Courier Waybill
                          </v-subheader>
                          <v-divider></v-divider>
                          <!-- <v-timeline align-top dense style=" max-height: 45vh; min-height: 10vh; overflow-y: auto;" v-if="events"> -->
                              <!-- <v-timeline-item color="primary" small v-for="(log, index) in event.data" :key="index" fill-dot> -->
                                <!-- <strong>{{ log.title }}</strong> -->
                                <!-- <div class="text-caption"> -->
                                  <!-- {{ formatDate(log.time) }} -->
                                <!-- </div> -->
                              <!-- </v-timeline-item> -->
                            <!-- </v-timeline> -->
                        </v-card>
                    </div>
                </v-col>
            </v-row>
            <v-dialog v-model="shippingLineModal" width="400px" :fullscreen="$vuetify.breakpoint.mobile" >
              <v-card>
                <v-toolbar flat color="transparent">
                  <v-toolbar-title>Set Carrier</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn text @click="shippingLineModal = false">X</v-btn>
                </v-toolbar>
                <v-card-text>
                  <v-text-field
                    dense
                    outlined
                    rounded
                    prepend-inner-icon="search"
                    autofocus
                    placeholder="Search"
                    clearable
                    v-model="searchShippingLine"
                  ></v-text-field>
                  <v-list dense style="max-height: 50vh; overflow-y: auto">
                    <v-list-item
                      v-for="carrier in filterShippingLines"
                      :key="carrier.id"
                      @click="setShippingLine(carrier)"
                    >
                      <v-list-item-action>
                        <v-avatar v-if="carrier.logo" color="white">
                          <v-img :src="carrier.logo" contain></v-img>
                        </v-avatar>
                        <v-avatar v-else color="secondary">
                          <h3>{{ carrier.name.charAt(0) }}</h3>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>{{ carrier.name }}</v-list-item-title>
                        <v-list-item-subtitle>{{
                          carrier.friendlyName
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="voyageNumberModal"
              persistent
              width="400px"
              :fullscreen="$vuetify.breakpoint.mobile"
            >
              <v-card>
                <v-toolbar flat dense color="transparent">
                  <v-spacer></v-spacer>
                  <v-btn text @click="voyageNumberModal = false">X</v-btn>
                </v-toolbar>
                <v-card-text>
                  <v-text-field
                    label="Voyage No"
                    v-model="order.mainCarriageConveyanceNumber"
                    outlined
                    dense
                    clearable
                    @change="$emit('orderChange')"
                  ></v-text-field>
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="sailingScheduleError"
              width="400px"
              :fullscreen="$vuetify.breakpoint.mobile"
            >
              <v-card>
                <v-card-text class="text-left mt-3 pt-5">
                  <p style="font-size: 16px">
                    Please select make sure to select the following before continuing:
                  </p>
                  <ul>
                    <li v-if="!order.portOfLoadValue">Port of Load (POL)</li>
                    <li v-if="!order.portOfDischargeValue">Port of Discharge (POD)</li>
                  </ul>
                  <v-row justify="center" class="mt-5 mb-3">
                    <v-btn
                      color="blue"
                      class="mx-2"
                      text
                      small
                      @click="sailingScheduleError = false"
                      >Okay</v-btn
                    >
                  </v-row>
                </v-card-text>
              </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>
import OverviewShipment from './OverviewShipment.vue';
import OverviewOrder from './OverviewOrder.vue';
import OverviewContainer from './OverviewContainer.vue';
export default {
components: {
    OverviewShipment,
    OverviewOrder,
    OverviewContainer
},
inject: ['data'],
props: {
    shipment: {
        type: Object,
        required: false
    },
    shipmentItems: {
        type: Array,
        required: false
    },
    productSummary: {
        type: Array,
        required: false
    },
    documents: {
        type: Array,
        required: false
    },
    relationships: {
        type: Array,
        required: false
    },
    bookings: {
        type: Array,
        required: false
    },
    stackDates: {
        type: Object,
        required: false
    },
    order: {
        type: Object,
        required: false
    },
    selectedType: {
        type: String,
        required: true,
        default: 'shipment'
    },
    orderContents: {
        type: Array,
        required: false
    },
    loadingOrderContents: {
        type: Boolean,
        required: false
    },
    loadingContainerContents: {
        type: Boolean,
        required: false
    },
},
data: () => ({
    containers: [],
    contentView: 'shipment',
    selectedBooking: null,
    selectedContainer: null,
    updateViewKey: 0,
    shippingLineModal: false,
    searchShippingLine: "",
    voyageNumberModal: false,
    sailingScheduleError: false,
    submittingBookingRequest: false,
    productSummaryHeaders: [
      {
        text: "Product",
        value: "productName",
        align: "center",
      },
      {
        text: "Variety",
        value: "varietyName",
        align: "center",
      },
      {
        text: "Pack Code",
        value: "packCode",
        align: "center",
      },
      {
        text: "Grade/Class",
        value: "gradeClassCode",
        align: "center",
      },
      {
        text: "Count/Size",
        value: "countSize",
        align: "center",
      },
      {
        text: "No. Cartons",
        value: "noCartons",
        align: "center",
      },
      {
        text: "Percentage",
        value: "percentage",
        align: "center",
      },
      // {
      //   text: "Price per Carton",
      //   value: "cartonPrice",
      //   align: "center",
      // },
      // {
      //   text: "Price per KG",
      //   value: "kgPrice",
      //   align: "center",
      // },
    ],
    bookingStatus: [
      { name: "Draft", icon: "edit_note", color: "#F57C00" },
      { name: "Pending", icon: "pending", color: "#009688" },
      { name: "Requested", icon: "verified", color: "#2196F3" },
      { name: "Confirmed", icon: "check_circle_outline", color: "#007d5c" },
      { name: "Ghost", icon: "help", color: "#607D8B" },
      { name: "Cancelled", icon: "cancel", color: "#F44336" },
      { name: "Rejected", icon: "warning", color: "#F44336" },
    ],
}),
watch: {
    "data": {
      immediate: true,
      handler(newVal, oldVal) {
          this.setContainerData(newVal)
      }
    },
    'selectedType': {
        immediate: true,
        handler(newVal, oldVal) {
          if(sessionStorage.getItem('TradingShortcut')) {
            let item = JSON.parse(sessionStorage.getItem('TradingShortcut'))
            if(item?.shortcut == 'container') {
              return
            }
          } else {
            if(newVal == 'shipment') {
              this.contentView = 'shipment';
            } else if(newVal == 'order') {
              this.contentView = 'order';
            } else if(newVal == 'container') {
              this.contentView = 'container';
            }
          }
        }
    
    }
},
created() {
    
},
computed: {
  milestones() {
        let milestones = []
        if(this.selectedContainer?.containerMilestones) {
            milestones = JSON.parse(JSON.stringify(this.selectedContainer?.containerMilestones)) 
        }
        milestones.sort((a, b) => {
            return new Date(a.createdAt) - new Date(b.createdAt)
        })

        return milestones
    },
    filterShippingLines() {
    let result = this.shippingLines;
      if (this.searchShippingLine) {
        result = result.filter(
          (x) =>
            x.name
              .toLowerCase()
              .includes(this.searchShippingLine.toLowerCase()) ||
            (x.friendlyName &&
              x.friendlyName
                .toLowerCase()
                .includes(this.searchShippingLine.toLowerCase()))
        );
      }
      return result;
    },
    weekNumbers(){
      let result = []
      for(let i = 1; i <= 52; i++){
        result.push(i)
      }
      return result
    },
},
methods: {
    setContainerData(data) {
      this.containers = data.containers
      if(sessionStorage.getItem('TradingShortcut')) {
          let item = JSON.parse(sessionStorage.getItem('TradingShortcut'))
          if(item?.shortcut == 'container') {
            let find = this.containers.find(x => x.id == item.id)
            if(find) {
              this.selectedContainer = find
              this.changeView('container', find)
            }
          }
      }
    },
    async submitBookingRequest(){
      this.submittingBookingRequest = true;
      await this.$API.updateBooking({
        bookingRequestStatus: "SUBMITTED",
        id: this.order.id,
        bookingHandlerId: this.order.forwarderId,
        customerId: this.$store.state.currentOrg.id
      })
      this.$message.success("Booking request submitted")
      this.order.bookingRequestStatus = "SUBMITTED"
      this.submittingBookingRequest = false
    },
    openSailingSchedules() {
      if (this.order.portOfLoadValue && this.order.finalDestinationValue) {
        this.sailingScheduleModal = true;
      } else {
        this.sailingScheduleError = true;
      }
    },
    statusColor(status) {
      let color = this.bookingStatus.find(
        (x) => x.name.toLowerCase() === status.toLowerCase()
      ).color;
      return color;
    },
    setShippingLine(item) {
      this.order.shippingLine = item;
      this.order.shippingLineId = item.id;
      this.order.carrier = item;
      this.order.carrierId = item.id;
      this.shippingLineModal = false;
      if (!this.order.mainCarriageConveyanceNumber) {
        this.voyageNumberModal = true;
      }
      this.$emit("orderChange");
    },
    changeView(type, item) {
        this.selectedContainer = item;  
        this.contentView = type;
        this.updateViewKey++;
    },
    viewShipmentDetails() {
        this.selectedType = 'shipment';
        this.$emit('viewShipmentDetails');
    },
    openDocumentSummary(document) {
        this.$emit('openDocumentSummary', document);
    },
}
}

</script>

<style scoped>
* {
  font-size: 12px;
  --lighterLayer: var(--v-greyRaised-lighten1) !important;
}
.leftSections {
    height: 100%;
    width: 10%;
    min-width: 10%;
    max-width: 10%;
    background:  var(--v-greyDarker-base) !important;
}
.rightSection {
    height: 100%;
    width: 100%;
    min-width: 85%;
    max-width: 100%;
    background: var(--v-greyRaised-base) !important;
}

.contentSection {
    height: 100%;
    width: 100%;
    max-height: calc(100vh - 36px - 30px) !important;
    contain: content;
    overflow-x: hidden;
    overflow-y: auto;
}

.listLeft {
    background-color: transparent !important;
}
.listItemLeft {
    min-height: 38px;
    height: 38px;
    padding: 0 0px !important;
}

.v-list-item {
    min-height: 38px;
    height: 38px;
    padding: 0 0px !important;
}

.toolbars ::v-deep .v-toolbar__content {
  padding: 0;
}
.v-list-item__avatar {
    margin: 0 2px 0 0 !important;
    height: 30px !important;
    min-width: 30px !important;
    width: 30px !important;

}
.v-list-item__action {
    margin: 0 2px 0 0 !important;
    height: 30px !important;
    min-width: 30px !important;
    width: 30px !important;
    display: flex !important;
    place-content: center !important;
}
.v-list-item__title {
    font-size: 0.75rem !important;
    line-height: 1.4 !important;
}

.v-list-item__subtitle {
    font-size: 0.625rem !important;
    opacity: 0.7 !important;
}
.infoCards {
  background: var(--lighterLayer) !important ; 
  contain: content;
  border-radius: 10px;
}
.timelineSmall ::v-deep .v-timeline-item__body {
    max-width: calc(100% - 24px) !important;
}
.timelineSmall ::v-deep .v-timeline-item {
    padding-bottom: 4px !important;
}
.timelineSmall ::v-deep .v-timeline-item__divider {
    min-width: 24px !important;
    align-items: center !important;
}
.timelineSmall::before {
    left: 11px !important;
}
.timelineSmall ::v-deep .v-timeline-item__dot, .timelineSmall ::v-deep .v-timeline-item__dot--small {
    height: 12px !important;
    width: 12px !important;
    box-shadow: none !important;
    background: transparent !important;
}
</style>